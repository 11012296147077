import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Sales Cloud",
            content: "While educating and empowering your customers, clients, members, workers, and partners, you can grow your business and your revenue."
        },
        {
            title: "Marketing Cloud and Pardot",
            content: "We are familiar with Salesforce, its broad range of use cases, and the advantages of Pardot and Marketing Cloud, two of the top marketing automation products available."
        },
        {
            title: "Experience Cloud",
            content: "Salesforce's Experience Cloud, formerly known as Community Cloud, aids in fostering relationships, boosting sales, and equipping users with the appropriate knowledge at the appropriate moment."
        },
        {
            title: "Cloud Financial Services",
            content: "With the top platform for financial services firms, you can guarantee customer pleasure, provide tailored journeys, and promote growth and innovation."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Salesforce Products</h2>
                                <p>The experts at Infograins can adapt your Salesforce solution for your company whether you're utilizing the Sales Cloud, Service Cloud, Marketing Cloud, or a custom cloud mix.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
