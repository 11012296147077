import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Why TypeScript</h2>
                                    <p>It’s reliable</p>
                                    <p>Because the compiler detects many errors, TypeScript code is far more trustworthy than code written in dynamically typed languages such as JavaScript. Less bugs equals happier customers.</p>
                                    <p>It’s explicit</p>
                                    <p>Because of the added type information, it is considerably easier to construct and maintain big codebases with TS without a lot of extra expense. More new features, less overhead.</p>
                                    <p>It’s fast to build in</p>
                                    <p>TypeScript is a high-level programming language that is easy to learn and use. It's ideal for prototypes, MVPs, and ventures that need to get to market quickly.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
