import React from 'react'
import AllCases from './AllCases'
import Banner from './Banner'

export default function Main() {
    return (
        <>
            <Banner />
            <AllCases />
        </>
    )
}
