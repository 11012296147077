import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import larvege from "../../../assets/images/hireDeveloperAdd/larvege.png"
const HireAddLaverage = () => {
    return (
        <section className='hireAddLaverage_wrap'>
            <Container>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className='hireAddLaverage_about'>
                            <h3 className='h3_title'><span>Leverage</span> The Skills Of Our Dedicated Developers</h3>
                            <p>Businesses need technical expertise to bloom. Sometimes, a developing company doesn't have enough resources to hire developers. We have a flexible engagement model that will match your requirements. Hire our developers to get on-time delivery of robust applications within your budget.</p>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <figure className='hireAddLaverage_img_wrap'>
                            <Image src={larvege} alt='Lavetage' fluid />
                        </figure>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HireAddLaverage