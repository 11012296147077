import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Our Services offerings in Unity 3D App Development</h2>
                <div className='description'>The best experience in Unity 3D app development belongs to our developers. To create the outstanding app, the experts make use of the latest equipment and technologies. The Unity 3D app development team invests a lot of time and effort in testing the apps. That eliminates the possibility of any potential difficulty in the future. Our Unity 3D app development concentrates on a single element that is related to the app's performance.
                  ,	Immersive Training Solution
                  ,	AR/VR Apps
                  ,	2D/3D Game Development
                  ,	Location based App
                </div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services