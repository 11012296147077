import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Use NLP Techniques to Transform Your Unstructured Data into Useful Insights</h2>
                <div className='description'>Infograins provides natural language processing services by fusing linguistics, machine learning, and artificial intelligence. Our team can assist you with integrating NLP capabilities into your applications, bots, and Internet of Things (IoT) devices. You can do this to reduce complexity and handle papers quickly. Your company may create a next-generation digital assistant that is contextually relevant, comprehends the language people use to communicate, and makes smarter decisions by utilizing our NLP knowledge.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services