import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Image, Row, Col } from 'react-bootstrap';
import logo1 from '../../../assets/images/hire/Group 17.png'
import logo2 from '../../../assets/images/hire/Group 20.png'
import logo4 from '../../../assets/images/hire/Group 18.png'
import logo3 from '../../../assets/images/hire/Litigation-Proof-Construction-01 (1).png'
import logo5 from '../../../assets/images/hire/Group 24.svg'

export default function LogoSlider() {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 4,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 6,

            }
        },
    };

    return (
        <>
      
                <section className='logoSlider'>
                <OwlCarousel className='owl-theme hero_slider' loop margin={10} {...options}>
                    <div className='item'>
                        <Image src={logo1} fluid />
                    </div>
                    <div className='item'>
                        <Image src={logo2} fluid />
                    </div>
                    <div className='item'>
                        <Image src={logo3} fluid />
                    </div>
                    <div className='item'>
                        <Image src={logo4} fluid />
                    </div>
                    <div className='item'>
                        <Image src={logo5} fluid />
                    </div>
                </OwlCarousel>
            </section>
             
            
        </>
    )
}
