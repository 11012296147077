import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

export default function CaseBanner(props) {
    const data = props.data
    return (
        <>
            <section className='case_studies_details_banner'>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='content_wrapper'>
                                <div className='banner_content'>
                                    <h2>{data && data?.title}</h2>
                                    <p>{data && data?.content}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='banner_img_wrapper'>
                                <Image src={data && data?.image} className="img_side" alt='banner' fluid/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
