import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import gameGirl from "../../../assets/images/trusted/aceRemove.png"

const GameCate = [
    {
        title:'Full Cycle Game Dev',
        content:'We will lead your project through all stages from game design to post-release support, using the extensive experience and creativity of our experts. Make your gaming dream come true with our customized full-cycle development services.'
    },
    {
        title:'Game Co-Development',
        content:'Empower your development opportunities and extend your team with competent level designers, senior programmers, and seasoned 2D and 3D artists. We understand the brand well, so our expertise can find the best application in any cooperation.'
    },
    {
        title:'Game Porting',
        content:'Reach an even larger audience and add more functionality to the gameplay with our professional porting services, making it easy to port mobile, desktop, VR/AR games from one platform to another and meet your expectations in terms of quality and budget.'
    },
]
const OurGame = () => {
    return (
        <>
            <section className='ourGame-wrap'>
                <Container>
                    {/* <div className='ourGame-title'>
                        <h3 className='h3_title'>{Head.subheading}</h3>
                        <h2 className='h2_title'>{Head.heading}</h2>
                    </div> */}
                    <Row>
                        {GameCate.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='ourGame-card-wrap'>
                                    <figure className="ourGame-card-img">
                                        <Image src={gameGirl} alt='Ace' />
                                    </figure>
                                    <div className='ourGame-card-detail'>
                                        <h3 className='h3_title'>{e.title}</h3>
                                        <p>{e.content}</p>
                                    </div>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurGame