import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
const Teams = ['Sunrisers Hyderabad', 'Mumbai Indians', 'Royal Challengers Bangalore', 'Kolkata Knight Riders', 'Kings XI Punjab', 'Chennai Super Kings', 'Rajasthan Royals', 'Delhi Capitals']
export default function IPLForm() {
    const [Result1, setResult1] = useState('')
    const [Result2, setResult2] = useState('')
    // ================================ Validation ====================================
    const { register, reset, formState: { errors }, handleSubmit, watch } = useForm()
    const BatTeam = watch('BattingTeam')
    const BallTeam = watch('BowlingTeam')
    // ================================ Form Field Data ====================================
    const FormInput = [
        {
            id: 'a1',
            fieldType: 'select',
            controlId: 'formGroupBattingTeam',
            label: 'Batting Team',
            options: Teams.filter(e => {
                if (BallTeam !== undefined || BallTeam !== '') {
                    return BallTeam !== e
                } else {
                    return BallTeam
                }
            }),
            validateProp: {
                ...register('BattingTeam', {
                    required: 'Please enter value of BattingTeam',
                })
            }
        },
        {
            id: 'a2',
            fieldType: 'select',
            controlId: 'formGroupBowling',
            label: 'Bowling Team',
            options: Teams.filter(e => {
                if (BatTeam !== undefined && BatTeam !== '') {
                    return BatTeam !== e
                } else {
                    return BatTeam
                }
            }),
            validateProp: {
                ...register('BowlingTeam', {
                    required: 'Please enter value of BowlingTeam',
                })
            }
        },
        {
            id: 'a3',
            fieldType: 'select',
            controlId: 'formGroupHostCity',
            label: 'City',
            options: ['Hyderabad', 'Bengaluru', 'Mumbai', 'Indore', 'Kolkata', 'Delhi', 'Chandigarh', 'Chennai', 'Ahmedabad'],
            validateProp: {
                ...register('City', {
                    required: 'Please enter value of City',
                })
            }
        },
        {
            id: 'a4',
            fieldType: 'input',
            controlId: 'formGroupTarget',
            label: 'Target',
            labelDetail: 'Range between (0-300)',
            type: 'number',
            placeholder: 'Enter Target',
            min: 0,
            max: 300,
            validateProp: {
                ...register('Target', {
                    required: 'Please enter value of Target',
                    min: {
                        value: 0,
                        message: "Minimum value is 0",
                    },
                    max: {
                        value: 300,
                        message: 'Maximum value is 300'
                    }
                })
            }
        },
        {
            id: 'a5',
            fieldType: 'input',
            controlId: 'formGroupScore',
            label: 'Score',
            labelDetail: 'Range between (0-300)',
            type: 'number',
            placeholder: 'Enter Score',
            min: 0,
            max: 300,
            validateProp: {
                ...register('Score', {
                    required: 'Please enter value of Score',
                    min: {
                        value: 0,
                        message: "Minimum value is 0",
                    },
                    max: {
                        value: 300,
                        message: 'Maximum value is 300'
                    }
                })
            }
        },
        {
            id: 'a6',
            fieldType: 'input',
            controlId: 'formGroupOver',
            label: 'Over',
            labelDetail: 'Range between (0-20)',
            type: 'number',
            placeholder: 'Enter Over',
            min: 0,
            max: 20,
            validateProp: {
                ...register('Over', {
                    required: 'Please enter value of Over',
                    min: {
                        value: 0,
                        message: "Minimum value is 0",
                    },
                    max: {
                        value: 20,
                        message: 'Maximum value is 20'
                    }
                })
            }
        },
        {
            id: 'a7',
            fieldType: 'input',
            controlId: 'formGroupWicket',
            label: 'Wicket',
            labelDetail: 'Range between (0-10)',
            type: 'number',
            placeholder: 'Enter Wicket',
            min: 0,
            max: 10,
            validateProp: {
                ...register('Wicket', {
                    required: 'Please enter value of Wicket',
                    min: {
                        value: 0,
                        message: "Minimum value is 0",
                    },
                    max: {
                        value: 10,
                        message: 'Maximum value is 10'
                    }
                })
            }
        },
    ]
    // ================================ Form API ====================================
    const onSubmit = async (data) => {
        const form = new FormData()
        form.append("Batting_Team", data.BattingTeam)
        form.append("Bowling_Team", data.BowlingTeam)
        form.append("City", data.City)
        form.append("Target", Number(data.Target))
        form.append("Score", Number(data.Score))
        form.append("Over_Completed", Number(data.Over))
        form.append("Wickets_Out", Number(data.Wicket))
        // const bodyData = {
        //     "Batting_Team": data.BattingTeam,
        //     "Bowling_Team": data.BowlingTeam,
        //     "City": data.City,
        //     "Target": Number(data.Target),
        //     "Score": Number(data.Score),
        //     "Over_Completed": Number(data.Over),
        //     "Wickets_Out": Number(data.Wicket)
        // }
        // console.log(bodyData);
        for (const iterator of form) {
            console.log(iterator);
        }
        try {
            const api = await axios.post('https://dp.infosense.tech/core/api/predictApi', form)
            console.log(api);
            setResult1(api.data.result1)
            setResult2(api.data.result2)
        } catch (error) {
            console.log("sdfsdfd",error);
        }
    }

    // ================================ Form JSX ====================================
    const FormField = ({ fieldType, options, controlId, label, labelDetail, type, placeholder, min, max, validateProp: { name, ...rest } }) => (

        fieldType === 'input' ?
            <Form.Group className="mb-3" controlId={controlId} >
                <Form.Label>{label}</Form.Label>
                <Form.Control type={type} placeholder={placeholder} name={name} {...rest} min={min} max={max} onWheel={(e) => e.target.blur()} />
                <Form.Text>{labelDetail}</Form.Text>
                {
                    errors[name] &&
                    <p className="know-erroe-text">
                        {errors[name].message}
                    </p>
                }
            </Form.Group>
            :
            <Form.Group className="mb-3" controlId={controlId} >
                <Form.Label>{label}</Form.Label>
                <Form.Select aria-label={label} name={name} {...rest}>
                    <option value={''}>Select The Batting Team</option>
                    {
                        options.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))
                    }
                </Form.Select>
                {
                    errors[name] &&
                    <p className="know-erroe-text">
                        {errors[name].message}
                    </p>
                }
            </Form.Group>
    )

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {FormInput.map(({ id, ...options }) => (
                        <Col lg={6} key={id}>
                            <FormField {...options} />
                        </Col>
                    ))}
                    <Col lg={6}>
                        <Form.Group className="pb-3 d-flex align-items-end h-100" controlId="formGroupEmail">
                            <Button className='w-100' variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <h2 className='mb-4'>Result </h2>
            <h4>Batting Team : {Result1}</h4>
            <h4>Bowling Team : {Result2}</h4>
        </>
    )
}