import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Gathering requirements',
        para: 'To start our project, we prioritize obtaining the necessary needs, materials, and data.'
    },
    {
        title: 'UI/UX Design ',
        para: 'Using the most up-to-date design technologies, we produce eye-catching and endearing designs that are the most user-friendly possible.'
    },
    {
        title: 'Prototype ',
        para: 'After designing, you will receive your prototype, which will be used to advance the product development process.'
    },
    {
        title: 'Improvement',
        para: 'Beginning with the most recent tools and technology, development of the blockchain, online, and mobile applications has been transparent.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Process We Follow</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card