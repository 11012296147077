import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>Why Kotlin?</h2>
                <div className='description'>Cooperation with Java
,
                  Simple migration
,
                  Multiplatform compatibility
,
                  Android Jetpack assistance
,
                  Tool-friendly terminology
,
                  Improved codes
,
                  Less duplication, less error
,
                  Community assistance
                </div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services