import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Planning and Research',
        content:'During this stage, we will collect project requirements from you and collaborate with you to understand your requirements. We will match your company with the best JavaScript developers and extra team members as needed. We will also plan the project according to your specifications.'
    },
    {
        title: 'Design and Development',
        content:'This is the time when our JavaScript professionals will design and build the product itself, whether it\'s a website or an application.'
    },
    {
        title: 'Testing',
        content:'No project is complete unless it has been thoroughly tested. We will rigorously analyze many parts of the product throughout the QA process to guarantee that it is functional, high-performance, useful, and more. We will also identify and delete any bugs that we come across.'
    },
    {
        title: 'Deployment',
        content:'This is the point at which your product is ready to hit the market. We will not deploy it until we have determined that it is actually ready.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our JavaScript Development Methodology</h2>
                                <p>Every JavaScript project is unique. However, these are the most common phases.</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
