import React, { useState, useEffect, useRef } from 'react'
import { Form, Container, Row, Col, Image, Nav, Tab, Spinner, Offcanvas } from 'react-bootstrap'
import blogs from '../../assets/images/insights/blog.webp'
import caseStudyImg from '../../assets/images/insights/case-study.webp'
import EngagementModelImg from '../../assets/images/insights/Engagement-models.webp'
import { IoMdArrowDropdown } from 'react-icons/io'
import Mail_icon from '../../assets/media/gif/message.gif'
import InfograinsIcon from '../../assets/media/logo-infograins.webp'
import { BsDot } from 'react-icons/bs'
import { AiOutlineMessage } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import popupGif from "../../assets/media/CUNSTULTANT.webp";
import { BiConversation } from "react-icons/bi";
import axios from 'axios'
import { Link, useLocation } from "react-router-dom";
import { IoIosWarning } from 'react-icons/io'
import NFT_bg from '../../assets/media/nft_bg.webp'
import Slice_bg from '../../assets/media/slice3ss.webp'
import Game_bg from '../../assets/media/games-d.webp'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { BsSkype } from 'react-icons/bs'
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet";
import career from '../../assets/images/career.webp'
import mission from '../../assets/images/mission.webp'
import team from '../../assets/images/team/tem.webp'
import about from '../../assets/images/about.webp'
import contact from '../../assets/images/contact.webp'
import alliance from '../../assets/images/alliance.webp'
import WebDevImg from '../../assets/media/web-deve.gif'
import MobDevImg from '../../assets/media/mob-deve.gif'
import UIUXDevImg from '../../assets/media/uiux.jpg'
import AIMLDevImg from '../../assets/media/aiml.svg'
import IOTDevImg from '../../assets/media/iot.jpg'
import SaleDevImg from '../../assets/media/sale.jpg'
import TestDevImg from '../../assets/media/test.jpg'
import GameDevImg from '../../assets/media/game-deve.gif';
import { AiFillCaretDown } from "react-icons/ai";
import { ServiceMenu } from "./mobileMenus/ServiceMenu";
import { HireMenus } from "./mobileMenus/HireMenus";
import { OurProductsMenus } from "./mobileMenus/OurProductsMenus";
import { InsightsMenus } from "./mobileMenus/InsightsMenus";
import { OurCompanyMenus } from "./mobileMenus/OurCompanyMenus";
import "./../../assets/style/common/_newMobileMenus.scss"
export default function Header() {
  useEffect(() => {
    window.scrollBy({
      top: 100, // could be negative value
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  // ======================================== Meta Tag Content =================================================
  const { pathname } = useLocation();

  // console.log("pathname",pathname.slice(1));
  async function MetaTags_Content() {
    try {
      const title_tag = document.getElementsByTagName('title')
      const meta_description = document.getElementsByTagName('meta');
      // console.log('meta', process.env.REACT_APP_BASE_URL);
      const { data: { response: { metacontent: { title, content } } } } = await axios.get(`${process.env.REACT_APP_BASE_URL}${pathname.slice(1)}`)
      meta_description.description.content = content
      title_tag[0].innerText = title
      // console.log({ title, content });
    } catch (error) {

    }
  }

  const [CompanyImg, setCompanyImg] = useState(about)
  function handleMouseOver(imagePath) {
    return () => {
      setCompanyImg(imagePath);
    };
  }

  useEffect(() => {
    MetaTags_Content()
  }, [pathname])
  // ====================================== popup validations ===================================
  const [input, setInput] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
    subject: ""
  });
  const [countryCodeData, setCountryCodeData] = useState([]);
  const [Error, setError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [success, seSuccess] = useState(false);
  // const [subjectError, setSubjectError] = useState("");


  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true)

    // ================ name =============================
    if (!input.name) {
      setNameError("Name is required");
      setTimeout(() => {
        setLoader(false)
      }, 100)
      return true;
    } else {
      setNameError("");
    }

    // ===================== email =========================
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!input.email) {
      setEmailError("Email is required")
      setTimeout(() => {
        setLoader(false)
      }, 100)
      return true;
    } else if (!input.email.match(mailformat)) {
      setEmailError("Please enter your valid email")
      setTimeout(() => {
        setLoader(false)
      }, 100)
      return true;
    } else {
      setEmailError("")
    }
    // ==================== Number =========================
    // var phoneno = /^\d{10}$/;
    // if (!input.number) {
    //     setNumberError("Number is required")
    // } else if (input.number.match(phoneno)) {
    //     setNumberError("")
    // } else {
    //     setNumberError("Please enter valid number")
    //     return true
    // }
    // ================ subject =============================
    if (!input.subject) {
      setSubjectError("Subject is required");
      setTimeout(() => {
        setLoader(false)
      }, 100)
      return true;
    } else {
      setSubjectError("");
    }
    // // ================ Message =============================
    const messageId = document.getElementById("message_Id").value;
    if (!messageId) {
      setMessage("Message is required");
      setTimeout(() => {
        setLoader(false)
      }, 100)
      return true;
    } else {
      setMessage("");
    }
    // ======================== concat number and dialingCode ==============================
    if (input.number !== "") {
      var mobilesData = document.getElementById("mobile").value;
      var concatData = mobilesData + input.number;
    } else {
      concatData = ""
    }
    // console.log("mobilesData", mobilesData);
    // ======================== concat number and dialingCode ==============================
    const payload = {
      dialingCode: mobilesData,
      contactNumber: concatData,
      fullName: input.name,
      emailId: input.email,
      message: input.message,
      subject: input.subject
    }
    var formdata = new FormData();
    formdata.append('get_contact_detail', JSON.stringify(payload));
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}contact_us/`,
      data: formdata,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res) {
        setInput({
          name: "",
          email: "",
          message: "",
          number: "",
          subject: ""
        })
        setLoader(false);
        setNumberError("")
      }
      document.body.style.overflow = "hidden"
      seSuccess(true);
      setTimeout(() => {
        document.body.style.overflow = "auto"
        seSuccess(false)
      }, 3000)
    }).catch(err => {
      setLoader(false)
      console.log("err", err);
      var numErr = JSON.parse(err.request.response);
      if (numErr.response === "Phone number is not valid!") {
        setNumberError("Phone number is not valid!")
        setTimeout(() => {
          setLoader(false)
        }, 100)
        return true
      } else {
        setNumberError("")
      }
    })

  }
  // ====================================== popup validations ===================================
  // ========================= Country Code =============================
  async function countryCode() {
    try {
      const api = await axios.get(`${process.env.REACT_APP_BASE_URL}get_country_dialing_code/`);
      const apiData = api.data.response.country_dialing_code;
      setCountryCodeData(apiData)
    } catch (error) {
      setError(true)
    }
  }

  useEffect(() => {
    countryCode()
  }, [])
  // ======================================== chandrakant Dropdown start ========================================
  function toggleDropdown_enter(e) {
    const currentDropdown = e.target.attributes.mainlink.nodeValue;
    document.getElementsByClassName('desk_dropdown')[currentDropdown].style.transform = 'scaleY(1)'
  }

  function toggleDropdown_leave(e) {
    const currentDropdown = e.target.attributes.mainlink.nodeValue;
    document.getElementsByClassName('desk_dropdown')[currentDropdown].style.transform = 'scaleY(0)'
  }
  // ======================================== chandrakant Dropdown end ========================================
  // ====================================== chandrakant toggle menu start ========================================
  function toggle_menu_icon(e) {
    if (e.target.classList.value.includes('open')) {
      e.target.classList.remove('open')
      document.getElementsByClassName('menu_bar1')[0].style.transform = 'rotate(0deg)';
      document.getElementsByClassName('menu_bar2')[0].style.transform = 'translateX(10px)';
      document.getElementsByClassName('menu_bar3')[0].style.transform = 'rotate(0deg)';
      document.getElementsByClassName('mobile_menu_section')[0].style.display = "none"
      document.getElementsByClassName('mobile_menu_div')[0].style.width = '0%'
    } else {
      e.target.classList.add('open')
      document.getElementsByClassName('menu_bar1')[0].style.transform = 'rotate(38deg)';
      document.getElementsByClassName('menu_bar2')[0].style.transform = 'translateX(40px)';
      document.getElementsByClassName('menu_bar3')[0].style.transform = 'rotate(-38deg)';
      document.getElementsByClassName('mobile_menu_section')[0].style.display = "block"
      document.getElementsByClassName('mobile_menu_div')[0].style.width = '100%'
    }
  }

  // ========================================= chandrakant toggle mobile menu list start ================================
  function toggleDropdown_mobile(e) {
    const currentClick = e.target.attributes.menulink.nodeValue;
    const dropLink = document.getElementsByClassName('mobile_drop_menu_list');
    const dropMenuLink = document.getElementsByClassName('mobile_drop_link');
    for (let i = 0; i < dropLink.length; i++) {
      if (dropLink[i].style.height === '210px') {
        dropLink[i].style.cssText = "height : 0px !important; transform : rotate(0deg)"
      }
    }

    const arrow = document.getElementsByClassName('downArrow');
    for (let i = 0; i < arrow.length; i++) {
      if (arrow[i].style.transform === 'rotate(180deg)') {
        arrow[i].style.transform = 'rotate(0deg)';
      }
    }
    if (e.target.classList.value.includes('open_mob_link')) {
      e.target.classList.remove('open_mob_link')
      document.getElementsByClassName('mobile_drop_menu_list')[currentClick].style.height = "0px"
      document.getElementsByClassName('mobile_drop_menu_list')[currentClick].style.padding = "0px"
      document.getElementsByClassName('downArrow')[currentClick].style.transform = "rotate(0deg)"

    } else {
      const index = e.target.attributes.menulink.nodeValue;
      e.target.classList.add('open_mob_link')
      for (let i = 0; i < dropMenuLink.length; i++) {
        if (dropMenuLink[i].classList.value.includes('open_mob_link')) {
          dropMenuLink[i].classList.remove('open_mob_link')
          dropMenuLink[index].classList.add('open_mob_link')
        } else {
        }
      }
      document.getElementsByClassName('mobile_drop_menu_list')[currentClick].style.cssText = "height : 210px !important;padding : 10px !important"
      document.getElementsByClassName('downArrow')[currentClick].style.transform = "rotate(180deg)"
    }
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // ========================================= chandrakant toggle mobile menu list end ================================

  // ===================================== Header API start ============================================ 
  // ===================================== Header API end ============================================ 

  useEffect(() => {
    var header = document.getElementById("myHeader");
    var header1 = document.getElementById("headContainer");
    var sticky;
    window.onscroll = function () { myFunction() };
    sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        header1.classList.add('head');
      } else {
        header.classList.remove("sticky");
        header1.classList.remove('head');
      }
    }
  }, [])

  // =============================== navdrop back on click ===========


  setTimeout(() => {

    const allLink = document.getElementsByClassName('desk_dropdown_link');

    for (let i = 0; i < allLink.length; i++) {
      allLink[i].addEventListener('click', (e) => {
        const index = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('mainlink');
        // console.log("index", index);
        document.getElementsByClassName('desk_dropdown')[index].style.transform = 'translateY(-500px)';
      })

    }
  }, 500);


  function closeMobileNav(e) {
    document.getElementsByClassName('menu_toggle_btn')[0].click()
  }


  // ========================================================== Top Bar Nav =============================================================

  const handleTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  // page redirect area
  const navigate = useNavigate();
  const redirect = useLocation();
  if (redirect.pathname === "/services/hire-mobile-app-developers" || redirect.pathname === "/services/hire-mobile-app-developers/") {
    window.location.pathname = '/hire-developers/app-developers/'
  }
  if (redirect.pathname === "/services/ionic-framework-development" || redirect.pathname === "/services/ionic-framework-development/") {
    window.location.pathname = '/mobile-development/ionic-app/'
  }
  if (redirect.pathname === "/services/android-app-development" || redirect.pathname === "/services/android-app-development/") {
    window.location.pathname = '/mobile-development/android-app/'
  }
  if (redirect.pathname === "/services/iphone-app-development" || redirect.pathname === "/services/iphone-app-development/") {
    window.location.pathname = '/mobile-development/ios-app/'
  }
  if (redirect.pathname === "/services/blockchain-development-company" || redirect.pathname === "/services/blockchain-development-company/") {
    window.location.pathname = '/blockchain-development/'
  }
  if (redirect.pathname === "/international-crypto-blockchain-&-metaverse-expo" || redirect.pathname === "/international-crypto-blockchain-&-metaverse-expo/") {
    window.location.pathname = '/event/international-crypto-blockchain-&-metaverse-expo-event/'
  }
  if (redirect.pathname === "/gitex-global" || redirect.pathname === "/gitex-global/") {
    window.location.pathname = '/event/gitex-global-event/'
  }
  if (redirect.pathname === "/event-dubai" || redirect.pathname === "/event-dubai/") {
    window.location.pathname = '/event/event-dubai'
  }
  if (redirect.pathname === "/international-crypto-blockchain-&-metaverse-expo-event" || redirect.pathname === "/international-crypto-blockchain-&-metaverse-expo-event/") {
    window.location.pathname = '/event/international-crypto-blockchain-&-metaverse-expo-event'
  }
  if (redirect.pathname === "/vibe-martech-fest-event" || redirect.pathname === "/vibe-martech-fest-event/") {
    window.location.pathname = '/event/vibe-martech-fest-event'
  }
  if (redirect.pathname === "/global-tech-innovation-summit-event" || redirect.pathname === "/global-tech-innovation-summit-event/") {
    window.location.pathname = '/event/global-tech-innovation-summit-event'
  }
  if (redirect.pathname === "/blockchain-economy-dubai-summi-event" || redirect.pathname === "/blockchain-economy-dubai-summi-event/") {
    window.location.pathname = '/event/blockchain-economy-dubai-summi-event'
  }
  if (redirect.pathname === "/fintech-surge-northstar-event" || redirect.pathname === "/fintech-surge-northstar-event/") {
    window.location.pathname = '/event/fintech-surge-northstar-event'
  }
  if (redirect.pathname === "/techspo-dubai-event" || redirect.pathname === "/techspo-dubai-event/") {
    window.location.pathname = '/event/techspo-dubai-event'
  }
  if (redirect.pathname === "/gitex-global-event" || redirect.pathname === "/gitex-global-event/") {
    window.location.pathname = '/event/gitex-global-event'
  }
  if (redirect.pathname === "/world-blockchain-summit-event" || redirect.pathname === "/world-blockchain-summit-event/") {
    window.location.pathname = '/event/world-blockchain-summit-event'
  }
  if (redirect.pathname === "/the-future-innovation-summit" || redirect.pathname === "/the-future-innovation-summit/") {
    window.location.pathname = '/event/the-future-innovation-summit'
  }
  if (redirect.pathname === "/crypto-expo-dubai-event" || redirect.pathname === "/crypto-expo-dubai-event/") {
    window.location.pathname = '/event/crypto-expo-dubai-event'
  }
  if (redirect.pathname === "/the-forex-expo-event" || redirect.pathname === "/the-forex-expo-event/") {
    window.location.pathname = '/event/the-forex-expo-event'
  }

  if (redirect.pathname === "/company/terms-conditions") {
    navigate('term_and_condition');
  }
  if (redirect.pathname === "/contact/contact-us-2/" || redirect.pathname === "/meet-with-us/") {
    navigate("/contactUs")
  }
  if (redirect.pathname === "/homepage-banner-mid-1-2/") {
    navigate("/")
  }
  if (redirect.pathname === "/blog/how-blockchain-will-change-organizations-sliceledger") {
    navigate("/products")
  }
  if (redirect.pathname === "/blog/ethereum-development-services-sliceledger") {
    navigate("/blockchain/ethereum-development/")
  }
  if (redirect.pathname === "/about-us/careers") {
    navigate("/career")
  }
  if (redirect.pathname === "/blockchain-services/public-blockchain-development") {
    navigate("/blockchain/public-blockchain-development/")
  }
  if (redirect.pathname === "/blockchain-services/private-blockchain-development") {
    navigate("/blockchain/private-blockchain-development/")
  }
  if (redirect.pathname === "/blockchain-services/poc-development") {
    navigate("/blockchain/poc-development/")
  }
  if (redirect.pathname === "/blockchain-services/smart-contract-audit") {
    navigate("/blockchain/smart-contracts-audit/")
  }
  if (redirect.pathname === "/blockchain-services/polkadot-and-cardano") {
    navigate("/blockchain/polkadot/")
  }
  if (redirect.pathname === "/blockchain-services/hybrid-blockchain") {
    navigate("/blockchain/hybrid-blockchain/")
  }
  if (redirect.pathname === "/blockchain-services/consortium-blockchain") {
    navigate("/blockchain/consortium/")
  }
  if (redirect.pathname === "/blockchain-services/decentralized-applications-dapps-development-company") {
    navigate("/blockchain/dapps/")
  }
  if (redirect.pathname === "/products/sliceledger-blockchain/slice-wallet/") {
    navigate("/product/slice-crypto-wallet/")
  }
  if (redirect.pathname === "/blockchain-services/decentralized-finance-defi-development-company") {
    navigate("/blockchain/defi-development/")
  }
  if (redirect.pathname === "/blockchain-services/blockchain-development-company-india") {
    navigate("/blockchain/public-blockchain-development/")
  }
  if (redirect.pathname === "/blog") {
    navigate("/blog/")
  }
  if (redirect.pathname === "/blockchain-services/") {
    navigate("/blockchain/public-blockchain-development/")
  }
  if (redirect.pathname === "/services/graphic-design") {
    navigate("/blockchain/public-blockchain-development/")
  }
  if (redirect.pathname === "/career") {
    navigate("/career/")
  }

  // page redirect area

  const navAbout = useNavigate()
  const handleNav = () => {
    navAbout("/aboutUs")
  }




  // ====================================== MOBILE MENUS ======================================
  window.addEventListener('resize', function updateScreenWidth() {
    if (window.innerWidth > 991) {
      document.querySelector("#mobile_menu").classList.remove("d-block");
      document.getElementsByClassName('menu_bar1')[0].style.transform = 'rotate(0deg)';
      document.getElementsByClassName('menu_bar2')[0].style.transform = 'translateX(10px)';
      document.getElementsByClassName('menu_bar3')[0].style.transform = 'rotate(0deg)';
    }
    // console.log("window.innerWidth", window.innerWidth);

  })

  const handleToggle = (e) => {
    if (e.target.classList.value.includes('open')) {
      e.target.classList.remove('open')
      document.getElementsByClassName('menu_bar1')[0].style.transform = 'rotate(0deg)';
      document.getElementsByClassName('menu_bar2')[0].style.transform = 'translateX(10px)';
      document.getElementsByClassName('menu_bar3')[0].style.transform = 'rotate(0deg)';
      document.querySelector("#mobile_menu").classList.remove("d-block");
      document.getElementsByTagName("body")[0].style.overflow = "auto"
    } else {
      e.target.classList.add('open')
      document.getElementsByClassName('menu_bar1')[0].style.transform = 'rotate(38deg)';
      document.getElementsByClassName('menu_bar2')[0].style.transform = 'translateX(40px)';
      document.getElementsByClassName('menu_bar3')[0].style.transform = 'rotate(-38deg)';
      document.querySelector("#mobile_menu").classList.add("d-block");
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    }
  }
  const handleMainMenu = (event) => {
    event.target.nextElementSibling.classList.toggle("d-block")
  }
  const handleInnerMenu = (event) => {
    event.target.nextElementSibling.classList.toggle("d-block")
  }
  // ========================================================================================
  const HoverDiv = useRef(null)
  function HoverNavLink({ currentTarget }) {
    const a = Number(currentTarget.getAttribute('data-move'))
    const b = Number(currentTarget.offsetHeight)
    HoverDiv.current.style.top = ((b * a) - b) + 'px'
  }

  function HoverLeave({ currentTarget }) {
    document.getElementsByClassName('hover_nav_link')[0].style.display = "none"
  }
  function HoverEnter({ currentTarget }) {
    document.getElementsByClassName('hover_nav_link')[0].style.display = "block"
  }
  return (
    // /* ============================ header area =============================*/
    <>
      <Helmet>
        <link rel="canonical" href={`https://infograins.com${pathname}`} />
      </Helmet>
      <section className='site_main_head'>
        <div className='top_most_bar'>
          <Container >
            <Row>
              <Col lg={12}>
                <div className='top_bar'>
                  <a href="mailto:info@infograins.com" className='mail_link'> <img src={Mail_icon} alt="mail" loading='lazy' /> info@infograins.com</a>
                  <div className='call_us_div'>
                    <div>Call Us :</div>
                    <ul className="call_us_list">
                      <li><div className="country_name">INDIA OFFICE</div>  <a href="tel:+919770477239">+919770477239</a>  <a className="ms-4" href="tel:+919713406272">+919713406272</a></li>
                      <li><div className="country_name">UK OFFICE</div>  <a href="tel:+447401232155">+447401232155</a>  </li>
                      <li><div className="country_name">USA OFFICE</div>  <a href="tel:+13312695159">+13312695159</a>  </li>
                      <li><div className="country_name">AUSTRALIA OFFICE</div>  <a href="tel:+61480043472">+61480043472</a>  </li>
                      <li><div className="country_name">UAE OFFICE</div>  <a href="tel:+971585596272">+971585596272</a>  </li>
                    </ul>
                  </div>
                  <div className='social_link_div'>
                    <a href="https://www.facebook.com/infograins/" target={'_blank'} rel='noreferrer'>  <FaFacebookF /> </a>
                    <a href="https://twitter.com/infograinssoft?s=20" target={'_blank'} rel='noreferrer'> <FaTwitter /> </a>
                    <a href="https://www.instagram.com/infograins/" target={'_blank'} rel='noreferrer'> <FaInstagram /> </a>
                    <a href="https://www.linkedin.com/company/infograin-software-solutions/" target={'_blank'} rel='noreferrer'> <FaLinkedinIn /> </a>
                    <a href="https://join.skype.com/invite/NqBQ11qKBCxI" target={'_blank'} rel='noreferrer'> <BsSkype /> </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='menu_bar_container'>
          <div className='menu_bar_div' id='myHeader'>
            <div className='animated_bar'></div>
            <Container fluid id='headContainer'>
              <Row>
                <Col lg={12}>
                  <div className='nav_bar'>
                    <div className='main_nav'>
                      <div className='site_logo'>
                        <div className='site_logo_img'>
                          <Link to='/' onClick={handleTop}>
                            <img src={InfograinsIcon} alt="site_logo" loading='lazy' />
                          </Link>
                        </div>
                      </div>
                      <div className='main_nav_list'>
                        <ul className='main_nav_ul'>
                          <li className='main_nav_link main_nav_Drop_link' mainlink="0" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Services <IoMdArrowDropdown /></li>
                          {/* <li className='main_nav_link main_nav_Drop_link' mainlink="1" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>NFT <IoMdArrowDropdown /> </li>
                          <li className='main_nav_link main_nav_Drop_link' mainlink="2" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Game <IoMdArrowDropdown /> </li> */}
                          <li className='main_nav_link main_nav_Drop_link' mainlink="3" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Hire Developer <IoMdArrowDropdown /> </li>
                          <li className='main_nav_link main_nav_Drop_link' mainlink="4" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Our Products <IoMdArrowDropdown /> </li>
                          {/* <li className='main_nav_link'><Link to="/aboutUs">About Us</Link></li> */}
                          <li className='main_nav_link main_nav_Drop_link' mainlink="5" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Insights <IoMdArrowDropdown /> </li>
                          <li className='main_nav_link main_nav_Drop_link' mainlink="6" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>Our Company <IoMdArrowDropdown /> </li>
                          {/* <li className='main_nav_link'><Link to='/blog'>Blogs</Link></li> */}
                          {/* <li className='main_nav_link'><Link to="/contactUs">Contact Us</Link></li> */}
                        </ul>
                        <button className='menu_toggle_btn' onClick={handleToggle}>
                          <div className='menu_bar1'></div>
                          <div className='menu_bar2'></div>
                          <div className='menu_bar3'></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* =============================================== Desktop Dropdown ======================================= */}
            <div className='desk_dropdown' mainlink="0" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="0">
                <Tab.Container id="left-tabs-example" defaultActiveKey='Mobile Development' mainlink="0">
                  <Row mainlink="0">
                    <Col sm={3} mainlink="0" className='service_sideNav'>
                      <Nav variant="pills" className="flex-column" mainlink="0" onMouseLeave={HoverLeave} onMouseEnter={HoverEnter}>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="1">
                          <div ref={HoverDiv} className='hover_nav_link'></div>
                          <Nav.Link eventKey={'Blockchain'} mainlink="0">Blockchain</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="2">
                          <Nav.Link eventKey={'Mobile Development'} mainlink="0">Mobile Development</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="3">
                          <Nav.Link eventKey={'Web Development'} mainlink="0">Web Development</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="4">
                          <Nav.Link eventKey={'Design'} mainlink="0">Design</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="5">
                          <Nav.Link eventKey={'AI and ML Development'} mainlink="0">AI & ML Development</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="6">
                          <Nav.Link eventKey={'IoT'} mainlink="0">IoT</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="7">
                          <Nav.Link eventKey={'Salesforce Solutions'} mainlink="0">Salesforce Solutions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="8">
                          <Nav.Link eventKey={'Software Testing'} mainlink="0">Software Testing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onMouseMove={HoverNavLink} mainlink="0" data-move="9">
                          <Nav.Link eventKey={'Game'} mainlink="0">Game</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9} mainlink="0">
                      <Tab.Content mainlink="0">
                        <Tab.Pane eventKey={'Mobile Development'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/ios-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">iOS App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/android-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              {/* =========== */}
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/leaf-prediction">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/property-forecaster">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/agriculture">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/ipl-prediction">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li><li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/diabetes-prediction">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/design">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development-services">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              <li mainlink="0" className='d-none'>
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Android App</div>
                                </Link>
                              </li>
                              {/* =========== */}
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/flutter-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Flutter</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/react-native-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">React Native </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/kotlin-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Kotlin</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/ionic-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Ionic</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/swift-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Swift</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/mobile-development/unity-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Unity App</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={MobDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Web Development'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/php">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">PHP</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/codeigniter">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">CodeIgniter</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/node-js">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Node.js</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/python">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Python</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/angular-js">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">AngularJs</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/react-js">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">ReactJs</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/next-js">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">NextJs</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/javascript">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">JavaScript</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/typescript">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">TypeScript</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/web-development/wordpress">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">WordPress</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={WebDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Design'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/design/web-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Web App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/design/mobile-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Mobile App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/design/wireframing-and-prototyping">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Wireframing and Prototyping</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/design/ui/ux-design">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">UI/UX Design</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={UIUXDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'AI and ML Development'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/object-recognition">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Object Recognition</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/text-to-speech">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Text To Speech</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/business-intelligence">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Business Intelligence</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/natural-language-processing">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Natural Language Processing</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/data-analytics">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Data Analytics</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/sentiment-analysis">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Sentimental Analysis</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/ai-development/alexa-skills">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Alexa Skills Development</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={AIMLDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'IoT'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/iot/iot-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">IoT App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/iot/iot-hardware-prototyping">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">IoT Hardware Prototyping</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/iot/iot-dashboard-and-analytics">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">IoT Dashboard and Analytics</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/iot/smart-home-automation">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Smart Home - Home Automation</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={IOTDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Salesforce Solutions'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/salesforce-solutions/development">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Salesforce Development</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/salesforce-solutions/consulting">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Salesforce Consulting</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/salesforce-solutions/implementation">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Salesforce Implementation</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={SaleDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Software Testing'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing/mobile-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Mobile App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing/web-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Web App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing/iot-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">IoT App</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing/ecommerce">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Ecommerce</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/software-testing/blockchain-app">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Blockchain App</div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={TestDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Blockchain'} mainlink="0">
                          <div className='d-flex' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/web3-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Web3</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/metaverse-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Metaverse</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/public-blockchain-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Public Blockchain </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/private-blockchain-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Private Blockchain </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/proof-of-concept-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Proof Of Concept </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/crypto-wallet-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Crypto Wallet </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/smart-contracts-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Smart Contracts </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/smart-contracts-audit/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Smart Contracts Audit</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/solidity-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Solidity</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/multichain/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Multichain</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/supply-chain/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Supply Chain</div>
                                </Link>
                              </li>
                            </ul>
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/finance/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Finance</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/transportation-logistics/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Transportation & Logistics</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/healthcare/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Healthcare</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/insurance/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Insurance</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/procure-to-pay-ptp/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Procure to Pay (PTP)</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/blockchain-protocol-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Blockchain Protocol</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/polkadot/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Polkadot</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/polygon/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Polygon</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/cardano/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Cardano</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/binance/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Binance</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/hybrid-blockchain/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Hybrid Blockchain</div>
                                </Link>
                              </li>
                            </ul>
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/ico-sto-ido/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">ICO, STO, IDO</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/consortium-blockchain-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Consortium Blockchain </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/token-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Token </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/solana-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Solana </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/ethereum-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Ethereum </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/decentralized-finance-defi/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Decentralized Finance (DEFI)</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/decentralized-applications-dapps/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Decentralized Applications (DAPPS)</div>
                                </Link>
                              </li>
                            </ul>
                            <ul mainlink="0">

                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/staking/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Staking</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/defi-yield-farming/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">DeFi Yield Farming</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/liquidity-pool/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Liquidity Pool</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/blockchain/rust-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Rust </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/nft/nft-marketplace-development">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">NFT Marketplace </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/nft/nft-development-company/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">NFT Development Company</div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/nft/nft-gaming-platform-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">NFT Gaming Platform </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/nft/semi-fungible-token-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Semi Fungible Token </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'Game'} mainlink="0">
                          <div className='d-flex justify-content-around' mainlink="0">
                            <ul mainlink="0">
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/game/unity-game-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Unity Game </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/game/p2e-game-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">P2E Game </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/game/web3-game-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Web3 Game </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/game/metaverse-game-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Metaverse Game </div>
                                </Link>
                              </li>
                              <li mainlink="0">
                                <Link className="desk_dropdown_link" mainlink="0" to="/game/ethereum-game-development/">
                                  <BsDot mainlink="0" />
                                  <div mainlink="0">Ethereum Game </div>
                                </Link>
                              </li>
                            </ul>
                            <div mainlink="0" style={{ width: '400px' }}>
                              <Image mainlink="0" src={GameDevImg} fluid />
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="1" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="1">
                <Row className='justify-content-around' mainlink="1">
                  <Col lg={3} xl={3} xxl={3} mainlink="1">
                    <div mainlink="1" className='dropdown_bg_image'>
                      <img src={NFT_bg} alt="nft-bg" loading='lazy' mainlink="1" />
                    </div>
                  </Col>
                  <Col lg={4} xl={4} xxl={4} mainlink="1">
                    <div className='desk_dropdown_col' mainlink="1">
                      <ul mainlink="1">
                        <li mainlink="1">
                          <Link className="desk_dropdown_link" mainlink="1" to="/nft/nft-marketplace-development/">
                            <BsDot mainlink="1" />
                            <div mainlink="1">NFT Marketplace Development</div>
                          </Link>
                        </li>
                        <li mainlink="1">
                          <Link className="desk_dropdown_link" mainlink="1" to="/nft/nft-development-company/">
                            <BsDot mainlink="1" />
                            <div mainlink="1">NFT Development Company</div>
                          </Link>
                        </li>
                        <li mainlink="1">
                          <Link className="desk_dropdown_link" mainlink="1" to="/nft/nft-gaming-platform-development/">
                            <BsDot mainlink="1" />
                            <div mainlink="1">NFT Gaming Platform Development</div>
                          </Link>
                        </li>
                        <li mainlink="1">
                          <Link className="desk_dropdown_link" mainlink="1" to="/nft/semi-fungible-token-development/">
                            <BsDot mainlink="1" />
                            <div mainlink="1">Semi Fungible Token Development</div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="2" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="2">
                <Row className='justify-content-around' mainlink="2">
                  <Col lg={3} xl={3} xxl={3} mainlink="2">
                    <div mainlink="2" className='dropdown_bg_image'>
                      <img src={Game_bg} alt="game-bg" loading='lazy' mainlink="2" />
                    </div>
                  </Col>
                  <Col lg={4} xl={4} xxl={4} mainlink="2">
                    <div className='desk_dropdown_col' mainlink="2">
                      <ul mainlink="2">
                        <li mainlink="2">
                          <Link className="desk_dropdown_link" mainlink="2" to="/game/unity-game-development/">
                            <BsDot mainlink="2" />
                            <div mainlink="2">Unity Game Development</div>
                          </Link>
                        </li>
                        <li mainlink="2">
                          <Link className="desk_dropdown_link" mainlink="2" to="/game/p2e-game-development/">
                            <BsDot mainlink="2" />
                            <div mainlink="2">P2E Game Development</div>
                          </Link>
                        </li>
                        <li mainlink="2">
                          <Link className="desk_dropdown_link" mainlink="2" to="/game/web3-game-development/">
                            <BsDot mainlink="2" />
                            <div mainlink="2">Web3 Game Development</div>
                          </Link>
                        </li>
                        <li mainlink="2">
                          <Link className="desk_dropdown_link" mainlink="2" to="/game/metaverse-game-development/">
                            <BsDot mainlink="2" />
                            <div mainlink="2">Metaverse Game Development</div>
                          </Link>
                        </li>
                        <li mainlink="2">
                          <Link className="desk_dropdown_link" mainlink="2" to="/game/ethereum-game-development/">
                            <BsDot mainlink="2" />
                            <div mainlink="2">Ethereum Game Development</div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="3" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="3">
                <Row className='justify-content-around' mainlink="3">
                  <Col lg={4} mainlink="3">
                    <ul mainlink="3">
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/blockchain-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Blockchain Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/golang-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Golang Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/dapps-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Dapps Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/ethereum-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Ethereum Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/hyperledger-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Hyperledger Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/nft-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">NFT Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/polygon-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Polygon Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/smart-contract-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Smart Contract Developers</div>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={4} xl={4} xxl={4} mainlink="3">
                    <ul mainlink="3">
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/web3-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Web3 Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/rust-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Rust Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/solidity-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Solidity Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/node-js-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Node JS Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/php-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">PHP Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/python-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Python Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/vue-js-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Vue Js Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/react-js-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">React Js Developers</div>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={4} xl={4} xxl={4} mainlink="3">
                    <ul mainlink="3">
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/app-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">App Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/flutter-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Flutter Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/react-native-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">React Native Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/ios-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">iOS Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/swift-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Swift Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/kotlin-developers">
                          <BsDot mainlink="3" />
                          <div mainlink="3">Kotlin Developers</div>
                        </Link>
                      </li>
                      <li mainlink="3">
                        <Link className="desk_dropdown_link py-1" mainlink="3" to="/hire-developers/it-staffing-solutions">
                          <BsDot mainlink="3" />
                          <div mainlink="3">IT Staffing Solutions</div>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="4" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="4">
                <Row className='justify-content-around' mainlink="4">
                  <Col lg={3} xl={3} xxl={2} mainlink="4">
                    <Link to={'/products'} mainlink="4" className='desk_dropdown_link dropdown_bg_image'>
                      <h5>View All Products</h5>
                      <div>
                        <div>
                          <img src={Slice_bg} alt="slice-bg" loading='lazy' mainlink="4" />
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col lg={4} xl={4} xxl={4} mainlink="4">
                    <div className='desk_dropdown_col' mainlink="4">
                      <ul mainlink="4">
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/bharat-nft-marketplace/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Bharat NFT Marketplace</div>
                          </Link>
                        </li>
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/bharat-token/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Bharat Token</div>
                          </Link>
                        </li>
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/slice-crypto-wallet/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Slice Crypto Wallet</div>
                          </Link>
                        </li>
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/slice-wallet-extension/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Slice Wallet Extension</div>
                          </Link>
                        </li>
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/bouquet-nft-collection/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Bouquet NFT Collection</div>
                          </Link>
                        </li>
                        <li mainlink="4">
                          <Link className="desk_dropdown_link" mainlink="4" to="/product/slice-ledger-protocol/">
                            <BsDot mainlink="4" />
                            <div mainlink="4">Slice Ledger Protocol</div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                </Row>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="5" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="5">
                <div mainlink="5">
                  <Row className='justify-content-center' mainlink="5">
                    <Col lg={3} xl={3} xxl={3} mainlink="5">
                      <div className='desk_dropdown_col' mainlink="5">
                        <div className='insight_deskDrop' mainlink="5">
                          <Link to="/blog" className='desk_dropdown_link' mainlink="5" style={{ flexDirection: 'column' }}>
                            <Image src={blogs} fluid alt='blogs' mainlink="5" loading='lazy' />
                            <div className='mt-4' mainlink="5">Blogs</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} xl={3} xxl={3} mainlink="5">
                      <div className='desk_dropdown_col' mainlink="5">
                        <div className='insight_deskDrop' mainlink="5">
                          <Link to="/case-study" className='desk_dropdown_link' mainlink="5" style={{ flexDirection: 'column' }}>
                            <Image src={caseStudyImg} alt='case-study' fluid mainlink="5" loading='lazy' />
                            <div className='mt-4' mainlink="5">Case Studies</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} xl={3} xxl={3} mainlink="5">
                      <div className='desk_dropdown_col' mainlink="5">
                        <div className='insight_deskDrop' mainlink="5">
                          <Link to="/engagement-models" className='desk_dropdown_link' mainlink="5" style={{ flexDirection: 'column' }}>
                            <Image src={EngagementModelImg} alt='engagement-model' loading='lazy' fluid mainlink="5" />
                            <div className='mt-4' mainlink="5">Engagement Models</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <div className='desk_dropdown' mainlink="6" onMouseEnter={toggleDropdown_enter} onMouseLeave={toggleDropdown_leave}>
              <Container mainlink="6">
                <div mainlink="6">
                  <Row className='justify-content-center d-none' mainlink="6">
                    <Col lg={2} xl={2} xxl={2} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <div className='insight_deskDrop' mainlink="6">
                          <Link to="/aboutUs" className='desk_dropdown_link' mainlink="6" style={{ flexDirection: 'column' }}>
                            <Image src={about} fluid mainlink="6" alt='about' loading='lazy' />
                            <div className='mt-4' mainlink="6">About Us</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xl={2} xxl={2} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <div className='insight_deskDrop' mainlink="6">
                          <Link to="/contactUs" className='desk_dropdown_link' mainlink="6" style={{ flexDirection: 'column' }}>
                            <Image src={contact} fluid mainlink="6" alt='contact' loading='lazy' />
                            <div className='mt-4' mainlink="6">Contact Us</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xl={2} xxl={2} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <div className='insight_deskDrop' mainlink="6">
                          <Link to="/career" className='desk_dropdown_link' mainlink="6" style={{ flexDirection: 'column' }}>
                            <Image src={career} fluid mainlink="6" alt='career' loading='lazy' />
                            <div className='mt-4' mainlink="6">Career</div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xl={2} xxl={2} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <div className='insight_deskDrop' mainlink="6">
                          <a href="#mission" onClick={handleNav} className='desk_dropdown_link' mainlink="6" style={{ flexDirection: 'column' }} >
                            <Image src={mission} fluid mainlink="6" alt='mission' loading='lazy' />
                            <div className='mt-4' mainlink="6">Mission and Vision</div>
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xl={2} xxl={2} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <div className='insight_deskDrop' mainlink="6">
                          <Link to="/team" className='desk_dropdown_link' mainlink="6" style={{ flexDirection: 'column' }} >
                            <Image src={team} fluid mainlink="6" alt='team' loading='lazy' />
                            <div className='mt-4' mainlink="6">Our Team</div>
                          </Link>
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <Row className='justify-content-around' mainlink="6">
                    <Col lg={3} xl={3} xxl={3} mainlink="6">
                      <div mainlink="6" className='dropdown_bg_image'>
                        <img src={CompanyImg} alt="our company" loading='lazy' className='our_company_img' mainlink="6" />
                        {/* <div className='our_company_images'></div> */}
                      </div>
                    </Col>
                    <Col lg={3} xl={3} xxl={3} mainlink="6">
                      <div className='desk_dropdown_col' mainlink="6">
                        <ul mainlink="6">
                          <li mainlink="6" onMouseEnter={handleMouseOver(about)}>
                            <Link to="/aboutUs" className='desk_dropdown_link ' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">About Us</div>
                            </Link>
                          </li>
                          <li mainlink="6" onMouseEnter={handleMouseOver(contact)}>
                            <Link to="/contactUs" className='desk_dropdown_link ' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">Contact Us</div>
                            </Link>
                          </li>
                          <li mainlink="6" onMouseEnter={handleMouseOver(career)}>
                            <Link to="/career" className='desk_dropdown_link' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">Career</div>
                            </Link>
                          </li>
                          <li mainlink="6" onMouseEnter={handleMouseOver(mission)}>
                            <a href="#mission" onClick={handleNav} className='desk_dropdown_link mission_vission' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">Mission and Vision</div>
                            </a>
                          </li>
                          <li mainlink="6" onMouseEnter={handleMouseOver(team)}>
                            <Link to="/team" className='desk_dropdown_link' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">Our Team</div>
                            </Link>
                          </li>
                          <li mainlink="6" onMouseEnter={handleMouseOver(alliance)}>
                            <Link to="/a-venture-with-cdn-solution" className='desk_dropdown_link' mainlink="6">
                              <BsDot mainlink="6" />
                              <div mainlink="6">Our Alliances</div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        </div>





        {/* =============================================== Mobile Menu ======================================= */}






        <nav id="mobile_menu" className="mobile_menu_wrap">
          <div className="mobile_main_list">
            <div className="main_menu_drop" onClick={handleMainMenu}>Service <AiFillCaretDown /></div>
            <div className="sub_menu_drop">
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Blockchain <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Blockchain") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Mobile Development <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Mobile Development") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Web Development <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Web Development") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Design <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Design") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>AI and ML Development <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "AI and ML Development") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>IoT <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "IOT") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Salesforce Solutions <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Salesforce Solutions") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Software Testing <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Mobile Development") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
              <div className="inner_sub_menu" onClick={handleInnerMenu}>Game <AiFillCaretDown /></div>
              <ul className="inner_sub_list">
                {
                  ServiceMenu.map((event, index) => {
                    if (event.type == "Game") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
            <div className="main_menu_drop" onClick={handleMainMenu}>Hire Developer <AiFillCaretDown /></div>
            <div className="sub_menu_drop">
              <ul>
                {
                  HireMenus.map((event, index) => {
                    if (event.type == "Hire Developers") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
            <div className="main_menu_drop" onClick={handleMainMenu}>Our Products <AiFillCaretDown /></div>
            <div className="sub_menu_drop">
              <div className="all_product_btn">
                <Link to='products'>All Products</Link>
              </div>
              <ul>
                {
                  OurProductsMenus.map((event, index) => {
                    if (event.type == "Our Product") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
            <div className="main_menu_drop" onClick={handleMainMenu}>Insights <AiFillCaretDown /></div>
            <div className="sub_menu_drop">
              <ul>
                {
                  InsightsMenus.map((event, index) => {
                    if (event.type == "Insights") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
            <div className="main_menu_drop" onClick={handleMainMenu}>Our Company <AiFillCaretDown /></div>
            <div className="sub_menu_drop">
              <ul>
                {
                  OurCompanyMenus.map((event, index) => {
                    if (event.type == "Our Company") {
                      return (
                        <li key={index}>
                          <Link to={event.slug}>{event.list}</Link>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
          </div>
        </nav>



































      </section >
      {/*========================= position fix Quote ========================*/}
      < section className='quote_wrap' >
        <Button onClick={handleShow}>
          <p> <AiOutlineMessage /> Get A <span>Free</span> Quote</p>
          <span className='popup-icn'><BiConversation /></span>
        </Button>
      </section >

      <Modal show={show} onHide={handleClose} animation={true} centered backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Get Free Consultation From Top Industry Experts</Modal.Title>
        </Modal.Header>
        <div className='quote-popup-wrap'>
          <figure className='quote-popup-img'>
            <img src={popupGif} alt="popup-gif" loading='lazy' />
          </figure>
          <Modal.Body className='quote-popup'>
            <div className="login-box">
              <form>
                <div className="user-box">
                  <label>Full Name</label>
                  <input type="text" name="name" value={input.name} onChange={handleChange} />
                  <small style={{ color: "red", fontSize: "12px" }}>{nameError}</small>
                </div>
                <div className="user-box">
                  <label>Email</label>
                  <input type="email" name="email" value={input.email} onChange={handleChange} />
                  <small style={{ color: "red", fontSize: "12px" }}>{emailError}</small>
                </div>
                <Form.Group className="mb-3">
                  <label className='phone_label_text'>Phone Number (optional)</label>
                  <div className='mobile_div mb-4'>
                    <Form.Select id='mobile'>
                      {Error ?
                        <option>00</option>
                        : countryCodeData.map((e, key) => {

                          return <option key={key} value={e.Dial}>{e.country_with_dialing_code}</option>
                        })}
                    </Form.Select>
                    <Form.Control type="number" min={0} id="userNumber" placeholder="Enter number" className='input_field' name="number" value={input.number} onChange={handleChange} />
                  </div>
                  <small style={{ color: "red", fontSize: "12px" }}>{numberError}</small>
                </Form.Group>
                <div className="user-box">
                  <label>Subject</label>
                  <input type="text" name="subject" value={input.subject} onChange={handleChange} />
                  <small style={{ color: "red", fontSize: "12px" }}>{subjectError}</small>
                </div>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}
                    className='input_field'
                    name='message'
                    value={input.message} onChange={handleChange} id="message_Id"

                  />
                  <small style={{ color: "red", fontSize: "12px" }}>{messageError}</small>
                </Form.Group>
                <a type='button' onClick={handleSubmit}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  {
                    loader ? <div className="item popup_loader" disabled><Loader type="spinner-circle" bgColor={"#fff"} color={'#FFFFFF'} size={40} /></div> : <div>Send</div>
                  }
                </a>
              </form>
            </div>
          </Modal.Body>
          {
            success ?
              <section className='congrats_popup get_popup'>
                <div className="svg-container">
                  <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                    <circle className="success" fill="#5bb543" cx="24" cy="24" r="22" />
                    <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                  </svg>
                  <p>Thanks for contacting us. We will contact you shortly</p>
                </div>
              </section> : ""
          }
        </div>
      </Modal>


      {/*=========================// position fix Quote====================== */}
    </>
    // /* ============================ // header area =============================*/
  )
}
