import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import news from "../../../assets/images/trusted/aceGame2.jpg"

const GameNews = () => {
    const GameCate = [
        {
            title: "Cost Reduction",
            content: "IoT and smart home technologies provide transparency in your family, as was already mentioned. This then enables you to significantly reduce your utility spending."
        },
        {
            title: "Impact on the Environment",
            content: "Here, the use of the Internet of Things in home automation is advantageous to everyone — home owners, their neighbors, the nation, and even the entire planet. By using resources as efficiently as possible, we can cut costs while also lowering our carbon footprint. In fact, the Internet of Things is the initiative behind green energy."
        },
        {
            title: "Enhanced comfort",
            content: "Intelligent algorithms that grant home appliances some autonomy and enable them to make situational decisions are frequently needed for IoT-based smart home automation."
        },
        {
            title: "Improved security",
            content: "A connected home kit typically includes home security systems like August locks or TP-Link cameras. Early adopters of smart homes mainly value increased security. But there are other methods for enhancing home security besides smart locks and security cameras."
        },
    ]
    return (
        <>
            <section className='gameNews-wrap'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center mb-4'>
                                {/* <h4 className='h4_title'>subheading</h4> */}
                                <h2 className='h2_title'>Advantages of Internet of Things for Smart Home</h2>
                                {/* <p className='description'>description</p> */}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                {
                                    GameCate.map((e, key) => {
                                        return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                            <div className='gameNews-card-wrap'>
                                                <figure className="gameNews-card-img">
                                                    <Image src={news} alt='News' fluid />
                                                </figure>
                                                <div className='gameNews-card-detail'>
                                                    <h3 className='h3_title'>{e.title}</h3>
                                                    <p>{e.content}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameNews