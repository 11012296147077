import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Our UI UX Design Services",
            content: "Our projects' best-in-class blend of technology and art captivates you with the elegance and style of images, audio, contents, and interactions. In the UI UX design services we give, the insights supplied by the creative vision, combined with the latest technology touch, result in a completely pleasant user experience."
        },
        {
            title: "Custom Web Design & Development",
            content: "We offer personalized and modernized website solutions that are specialized and created for your services and goods in order to meet your demands and achieve organizational goals. Our creative designers provide a plethora of sophisticated UI and UX designs while keeping your needs in mind."
        },
        {
            title: "Graphic Design & Corporate Branding",
            content: "Our professional designers recognize the importance of corporate branding and ensure the highest quality corporate graphic designs, which include building and refining the brand's graphical identity. The design elements give a visual representation of the brand, highlighting the organizational ideals."
        },
        {
            title: "Wireframing",
            content: "Our design team provides an overview of goods while keeping user requirements in mind, determining the flow and structure of design aspects. These wireframes help teams create effective prototypes and navigate the website structure without being sidetracked by other components."
        },
        {
            title: "Mobile App Design",
            content: "We ensure that mobile apps have a perfect appearance and feel, with high-quality user interface and user experience aspects. Our designers provide excellent ideas for creating mobile app designs that are faster to load, have an attractive appearance, are simple to use, and increase client happiness."
        },
        {
            title: "Wearables App Design",
            content: "Our team specialises at designing and developing enriched wearable app designs for your wearables, devices, and gadgets. We think beyond the box to deliver you a wide range of interfaces and experiences that work best with your smartwatches, fitness trackers, smart jewelry, head-mounted displays, and other wearable devices."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                {/* <h2 className='h2_title'>Our Services for Full-Stack Web App Development</h2> */}
                                {/* <p>We have a fantastic team of web development experts who are always testing and applying new ideas that will turn website designs into a gold mine for your company.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
