import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Make Speech Specific',
        para: 'The engine may alter the context and voice for the enterprises, making it simple to operate in accordance with that altered context. There are numerous variations and styling options for text-to-speech.'
    },
    {
        title: 'Employing Different Languages',
        para: 'Since there are more than 70 languages that are both spoken and written in the world, doing business would never be hampered by the language barrier. You can tailor your experience and use the user-friendly numerous languages.'
    },
    {
        title: 'Expand Your Audience',
        para: 'Text-to-speech technology enables marketers to reach a wider audience because it can translate any language. Additionally, it can assist you in expanding your clientele so that your business can run smoothly.'
    },
    {
        title: 'Boost brand worth',
        para: 'The value of the brand continuously rises with new facilities. Text-to-speech is the ideal illustration of how a brand\'s value may be raised by linking a broader audience and successful outcomes.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Benefits of Text to Speech:</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card