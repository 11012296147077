import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Agile Methods',
        content: 'We use agile systems, which start with an in-depth understanding of a client\'s needs. By working together, cross-functional teams will steadily improve our React Native development processes. '
    },
    {
        title: 'Budget-friendly',
        content: 'Our React Native team dissects a company\'s requirements and creates workable solutions. The tech stack that best satisfies the client\'s requirements and preferences will be recommended by us. '
    },
    {
        title: 'Transparency',
        content: 'We have total faith in openness. Nothing is hidden from the consumer, and they have access to all protocols and codes at any moment. At each stage of developing a React Native App, we inform our clients of our progress. '
    },
    {
        title: 'Adaptability',
        content: 'We provide flexibility in a number of ways. In terms of coding, working hours, the development cycle, enhancements, and round-the-clock assistance and maintenance, we are adaptable.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Features</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements