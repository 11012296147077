export const OurProductsMenus = [
    // ================================== OUR PRODUCTS ==================================
    {
        type: "Our Product",
        list: "Bharat NFT Marketplace",
        slug: "/product/bharat-nft-marketplace/",
    },
    {
        type: "Our Product",
        list: "Bharat Token",
        slug: "/product/bharat-token/",
    },
    {
        type: "Our Product",
        list: "Slice Crypto Wallet",
        slug: "/product/slice-crypto-wallet/",
    },
    {
        type: "Our Product",
        list: "Slice Wallet Extension",
        slug: "/product/slice-wallet-extension",
    },
    {
        type: "Our Product",
        list: "Bouquet NFT Collection",
        slug: "/product/bouquet-nft-collection",
    },
    {
        type: "Our Product",
        list: "Slice Ledger Protocol",
        slug: "/product/slice-ledger-protocol",
    }
]