import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Built-in Packages',
        content:'CI comes with built-in tool packages, which allows the developers to make changes in the web app easily.'
    },
    {
        title: 'MVC structure',
        content:'CI, which is based on MVC design, enables developers to quickly create a website or web app.'
    },
    {
        title: 'Easy bug resolution',
        content:'The CI framework is well-known for its remarkable error handling capabilities and ease of development.'
    },
    {
        title: 'Simple Coding Standards',
        content:'There are no restricted coding restrictions in CI, so developers can freely customize and develop the web app to your specifications.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>WHY CODEIGNITER DEVELOPMENT?</h2>
                                <p>As an open-source framework, CodeIgniter provides developers with flexibility when coding. This framework is popular among developers since it has no restrictive coding constraints. Experts in CodeIgniter can simply create fast and high-performance websites and online apps. CI has some of the top development options for your company, whether it is a start-up or an established firm.</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
