import React, { useState } from 'react'
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from 'react-router-dom';

export default function ScheduleMeetingForm(props) {
    const redirect = useNavigate()
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact_us, setcontact_us] = useState("");
    const [remark, setRemark] = useState("");

    const nameReg = /^[A-Za-z\s]{3,50}$/;
    const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const contactReg = /^[0-9]{10,}/;
    const para = window.location;

    const notify = () =>
        toast.success("Thanks for contacting us. We will contact you shortly");
    let formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("contact_us", contact_us);
    formData.append("remark", remark);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nameReg.test(name)) {
            document.getElementsByClassName("name-error")[props.error].innerHTML = "";
        } else {
            document.getElementsByClassName("name-error")[props.error].innerHTML =
                "Please Enter Valid Name";
            return
        }

        if (emailReg.test(email)) {
            document.getElementsByClassName("email-error")[props.error].innerHTML = "";
        } else {
            document.getElementsByClassName("email-error")[props.error].innerHTML =
                "Please Enter Valid Email";
            return
        }

        if (contactReg.test(contact_us)) {
            document.getElementsByClassName("contact-error")[props.error].innerHTML = "";
        } else {
            document.getElementsByClassName("contact-error")[props.error].innerHTML =
                "Please Enter Valid Number";
            return
        }

        if (name != "" && email != "" && contact_us != "") {
            setLoader(true);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}event/event_contact/`,
                    formData
                );
                if (response.data.success == true) {
                    setLoader(false);
                    notify();
                    setRemark('')
                    setEmail('')
                    setName('')
                    setcontact_us('')
                    redirect('/thank-you')
                }
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center text-white __wrapper">
                    <div className="row px-3 mb-3 konw-form-div-wi">
                        <div className="col-md-12 ">
                            <label htmlFor="">Name  <span className="text-danger">*</span> </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    className="know-schedule-inputs"
                                    placeholder="Your Full Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <p id="name-error" className="know-erroe-text name-error"></p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-flex justify-content-center text-white __wrapper">
                    <div className="row px-3 mb-3 konw-form-div-wi">
                        <div className="col-md-6 ">
                            <label htmlFor="">Contact  <span className="text-danger">*</span></label>
                            <div className="mt-1">
                                <PhoneInput
                                    inputClass='know-schedule-inputs'
                                    enableSearch={true}
                                    country={"us"}
                                    value={contact_us}
                                    onChange={(e) => setcontact_us(e)}
                                />

                                <p id="contact-error" className="know-erroe-text contact-error"></p>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <label htmlFor="">Email  <span className="text-danger">*</span></label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    className="know-schedule-inputs"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p id="email-error" className="know-erroe-text email-error"></p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-flex justify-content-center text-white __wrapper">
                    <div className='row px-3 mb-3 konw-form-div-wi'>
                        <div className="col-md-12 ">
                            <label htmlFor="">Message </label>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    type="text"
                                    value={remark}
                                    className="know-schedule-inputs"
                                    placeholder="Message"
                                    onChange={(e) => setRemark(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center text-white mb-5">
                    {loader ? (
                        <button type="submit" disabled className="know-schedule-btn">
                            <div className="item">
                                <Loader
                                    type="spinner-circle"
                                    bgColor={"#fff"}
                                    color={"#FFFFFF"}
                                    size={40}
                                />
                            </div>
                        </button>
                    ) : (
                        <button type="submit" className="know-schedule-btn">
                            Schedule a Meeting
                        </button>
                    )}

                </div>
            </form>
        </>
    )
}
