import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

export default function ThankYou(props) {
    const redirect = useNavigate()
    useEffect(() => {
        props.demo("top");
        setTimeout(() => {
            redirect('/')
        }, 10 * 1000);
    }, []);
    return (
        <>
            <section className='thank-you-page'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={6}>
                            <div>
                                <div class="content">
                                    <div class="wrapper-1">
                                        <div class="wrapper-2">
                                            <h1>Thank you !</h1>
                                            <p>Thanks for reaching out to us! </p>
                                            <p>Our dedicated team will review your message and respond to you promptly.</p>
                                            <Link to={'/'} class="go-home d-block">
                                                go home
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
