import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Achievements from './Achievements';
import HeroSection from './HeroSection';
import Card from './Card';
import Owl from './Owl';
import Services from './Services';
import Technologies from './Technologies';
import { Helmet } from 'react-helmet';

const AlexaSkills = (props) => {
    const { slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Alexa Skills Development Company</title>
                <meta name="description" content="Infograins has been marked as a well-known brand or a company in Alexa Skills development possessing an extensive knowledge in developing custom Alexa skills using ASK." />
            </Helmet>
            <HeroSection />
            <Achievements />
            <Services />
            <Technologies />
            <Owl />
            <Card />
        </>
    )
}

export default AlexaSkills