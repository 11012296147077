import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Hit the target with the best ReactJS web development company.</h2>
                                    <p>Have you been battling with ReactJS web development and not achieving the best business results? Infograins can assist you with this. ReactJS development is one of our specialized and targeted services, along with others. We build a bespoke solution and deliver the best by paying special attention to your business strategy. We now have:
                                        1.	Certified professionals with the most up-to-date knowledge of cutting-edge technologies and features such as Virtual DOM, JSK, and JavaScript.
                                        2.	Capabilities in harnessing the power of current technologies to create the best ReactJS applications.
                                        3.	JSK method to HTML element support in JavaScript and placement in DOM without createElement().
                                        4.	Our Experts will provide you with an in-depth consultation and flood mapping of your project.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
