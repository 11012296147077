import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import news from "../../../assets/images/trusted/aceGame2.jpg"

const GameNews = () => {
    const GameCate = [
        {
            title: "Actual Data",
            content: "Real-time services for IoT Dashboard and Analytics are offered. It aids in obtaining data and information quickly and effectively, and analytics aid in gathering pertinent data and insights. One can respond to recent developments or challenges and make decisions in real time thanks to real-time data."
        },
        {
            title: "Effective Monitoring",
            content: "The IoT gadgets offer a potent monitor that aids in gathering insight data. Monitoring and making the appropriate modifications with controlling support. Using a combination of IoT devices and sensors, the technology keeps an eye on both the inside and the outside."
        },
        {
            title: "Simplify complicated",
            content: "Things are more easily connected and simplified thanks to IoT technology. IoT Dashboard and Analytics assist in solving complex problems and bringing the simplest form of the goal. Using data analysis, tracking, and reporting, challenging situations can be made more manageable."
        },
        {
            title: "Unfolds possibilities",
            content: "The devices and IoT technology present numerous opportunities. The IoT Dashboards and Analytics convey the data to many methods with the most straightforward goal. The Dashboard identifies the various categories of data and analysis to demonstrate how the data is used properly."
        },
    ]
    return (
        <>
            <section className='gameNews-wrap'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center mb-4'>
                                {/* <h4 className='h4_title'>subheading</h4> */}
                                <h2 className='h2_title'>Benefits Of Using IoT Dashboard And Analytics</h2>
                                {/* <p className='description'>description</p> */}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                {
                                    GameCate.map((e, key) => {
                                        return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                            <div className='gameNews-card-wrap'>
                                                <figure className="gameNews-card-img">
                                                    <Image src={news} alt='News' fluid />
                                                </figure>
                                                <div className='gameNews-card-detail'>
                                                    <h3 className='h3_title'>{e.title}</h3>
                                                    <p>{e.content}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameNews