import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Over a Decade of Salesforce Consulting Under Our Belt</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>For more than ten years, we have provided clients from almost every industry with advice on their Salesforce platform digital transformation journeys while taking a personalized approach to their teams, objectives, and goals.</p>
                                    <p>We've handled even the most challenging tasks, so we've seen it all. Our certified Salesforce consultants offer you the collective knowledge to get you there whether you need assistance connecting, integrating, and simplifying systems or automating business processes.</p>
                                    <p>We can help you interact with customers, partners, and workers through every area of your Salesforce deployment and transform your business so that you get the most out of your platform investment.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
