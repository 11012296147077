import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Section3() {
    return (
        <>
            <section className='section_three'>
                <Container>
                    <Row>
                        <Col xl={7} lg={12} md={12} sm={12} className='content'>
                            <div>

                                <h2>Agile: Where Progress Meets Possibility.</h2>
                                <p>The world is constantly changing and software development is no exception. In this agile world, where uncertainties and discovery are the norms, software product development can be a formidable change. However, by adopting change and uncertainty and by using agile methodologies, teams can be more successful in delivering high-quality products that meet the needs of their users.</p>
                                <div className='btn_group'>
                                    <Link to={'/contactUs'}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                                                <path d="M17.7071 8.20711C18.0976 7.81658 18.0976 7.18342 17.7071 6.79289L11.3431 0.428931C10.9526 0.0384068 10.3195 0.0384069 9.92893 0.428931C9.53841 0.819456 9.53841 1.45262 9.92893 1.84314L15.5858 7.5L9.92893 13.1569C9.53841 13.5474 9.53841 14.1805 9.92893 14.5711C10.3195 14.9616 10.9526 14.9616 11.3431 14.5711L17.7071 8.20711ZM8.74228e-08 8.5L17 8.5L17 6.5L-8.74228e-08 6.5L8.74228e-08 8.5Z" fill="black" />
                                            </svg>
                                        </div>
                                        Know more
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xl={5} lg={12} md={12} sm={12}>
                            <div className='svg_wrapper_ani'>
                                <div className='text _1'>Plan</div>
                                <div className='text _2'>Design</div>
                                <div className='text _3'>Develop</div>
                                <div className='text _4'>Test</div>
                                <div className='text _5'>Deploy</div>
                                <div className='text _6'>Review</div>
                                <div className='text _7'>Launch</div>
                                <svg className='dots' width="556" height="253" viewBox="0 0 556 253" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5.00037" cy="248" r="5" transform="rotate(-90 5.00037 248)" fill="black" />
                                    <circle cx="134" cy="31" r="5" transform="rotate(-90 134 31)" fill="black" />
                                    <circle cx="284" cy="5" r="5" transform="rotate(-90 284 5)" fill="black" />
                                    <circle cx="423" cy="26" r="5" transform="rotate(-90 423 26)" fill="black" />
                                    <circle cx="551" cy="67" r="5" transform="rotate(-90 551 67)" fill="black" />
                                    <circle cx="284" cy="231" r="5" transform="rotate(-90 284 231)" fill="black" />
                                    <circle cx="156" cy="243" r="5" transform="rotate(-90 156 243)" fill="black" />
                                </svg>


                                <svg width="548" height="442" viewBox="0 0 548 442" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.00036 388.878L1.00036 252.52C1.00036 235.952 14.4318 222.52 31.0004 222.52L100.306 222.52C116.875 222.52 130.306 209.089 130.306 192.52L130.306 31.0001C130.306 14.4315 143.738 1.00002 160.306 1.00002L249.948 1.00002C266.516 1.00002 279.948 14.4315 279.948 31L279.948 192.52C279.948 209.089 266.516 222.52 249.948 222.52L181.793 222.52C165.225 222.52 151.793 235.952 151.793 252.52L151.793 411C151.793 427.569 165.225 441 181.793 441L249.948 441C266.516 441 279.948 427.569 279.948 411L279.948 270.763C279.948 254.195 293.379 240.763 309.948 240.763L388.846 240.763C405.414 240.763 418.846 227.332 418.846 210.763L418.846 83.1224C418.846 66.5539 432.277 53.1224 448.846 53.1224L517 53.1224C533.569 53.1224 547 66.5635 547 83.132C547 125.921 547 157.352 547 206.5" stroke="#F48533" strokeWidth="2" />
                                </svg>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
