import React from 'react';
import BannerForm from '../../common/BannerForm';
import { Container, Row, Col } from 'react-bootstrap';

const GameHero = () => {
    return (
        <>
            <section className='gameHero-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='gameHero-about-wrap'>
                                <h2 className='h2_title'>IOT Dashboard and Analytics</h2>
                                <h3 className='h3_title'></h3>
                                <p>Drive smart business decisions with our IOT Dashboard And Analytics solutions.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameHero