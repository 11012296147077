export const HireMenus = [
    // ================================== HIRE DEVELOPERS ==================================
    {
        type: "Hire Developers",
        list: "Blockchain Developers",
        slug: "/hire-developers/blockchain-developers",
    },
    {
        type: "Hire Developers",
        list: "Golang Developers",
        slug: "/hire-developers/golang-developers",
    },
    {
        type: "Hire Developers",
        list: "Dapps Developers",
        slug: "/hire-developers/dapps-developers",
    },
    {
        type: "Hire Developers",
        list: "Ethereum Developers",
        slug: "/hire-developers/ethereum-developers",
    },
    {
        type: "Hire Developers",
        list: "Hyperledger Developers",
        slug: "/hire-developers/hyperledger-developers",
    },
    {
        type: "Hire Developers",
        list: "NFT Developers",
        slug: "/hire-developers/nft-developers",
    },
    {
        type: "Hire Developers",
        list: "Polygon Developers",
        slug: "/hire-developers/polygon-developers",
    },
    {
        type: "Hire Developers",
        list: "Smart Contract Developers",
        slug: "/hire-developers/smart-contract-developers",
    },
    {
        type: "Hire Developers",
        list: "Web3 Developers",
        slug: "/hire-developers/web3-developers",
    },
    {
        type: "Hire Developers",
        list: "Rust Developers",
        slug: "/hire-developers/rust-developers",
    },
    {
        type: "Hire Developers",
        list: "Solidity Developers",
        slug: "/hire-developers/solidity-developers",
    },
    {
        type: "Hire Developers",
        list: "Node JS Developers",
        slug: "/hire-developers/node-js-developers",
    },
    {
        type: "Hire Developers",
        list: "PHP Developers",
        slug: "/hire-developers/php-developers",
    },
    {
        type: "Hire Developers",
        list: "Python Developers",
        slug: "/hire-developers/python-developers",
    },
    {
        type: "Hire Developers",
        list: "Vue Js Developers",
        slug: "/hire-developers/vue-js-developers",
    },
    {
        type: "Hire Developers",
        list: "React Js Developers",
        slug: "/hire-developers/react-js-developers",
    },
    {
        type: "Hire Developers",
        list: "App Developers",
        slug: "/hire-developers/app-developers",
    },
    {
        type: "Hire Developers",
        list: "Flutter Developers",
        slug: "/hire-developers/flutter-developers",
    },
    {
        type: "Hire Developers",
        list: "React Native Developers",
        slug: "/hire-developers/react-native-developers",
    },
    {
        type: "Hire Developers",
        list: "iOS Developers",
        slug: "/hire-developers/ios-developers",
    },
    {
        type: "Hire Developers",
        list: "Swift Developers",
        slug: "/hire-developers/swift-developers",
    },
    {
        type: "Hire Developers",
        list: "Kotlin Developers",
        slug: "/hire-developers/kotlin-developers",
    },
    {
        type: "Hire Developers",
        list: "IT Staffing Solutions",
        slug: "/hire-developers/it-staffing-solutions",
    }
]