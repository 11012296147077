import React, { useState, useEffect, useRef } from 'react'
import { Col, Container, Row, Form, FormGroup, Spinner } from 'react-bootstrap'
import axios from 'axios';
import Loader from "react-js-loader";
import BannerForm from '../../common/BannerForm';

export default function Description({ DescriptionData }) {
    // ====================================== popup validations ===================================
    const [input, setInput] = useState({
        name: "",
        email: "",
        message: "",
        number: "",
        subject: ""
    });
    const [countryCodeData, setCountryCodeData] = useState([]);
    const [Error, setError] = useState(false);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [messageError, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const [numberError, setNumberError] = useState("");
    const [success, seSuccess] = useState(false);
    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)

        // ================ name =============================
        if (!input.name) {
            setNameError("Name is required");
            setTimeout(() => {
                setLoader(false)
            }, 100)
            return true;
        } else {
            setNameError("");
        }

        // ===================== email =========================
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!input.email) {
            setEmailError("Email is required")
            setTimeout(() => {
                setLoader(false)
            }, 100)
            return true;
        } else if (!input.email.match(mailformat)) {
            setEmailError("Please enter your valid email")
            setTimeout(() => {
                setLoader(false)
            }, 100)
            return true;
        } else {
            setEmailError("")
        }
        // ==================== Number =========================
        // var phoneno = /^\d{10}$/;
        // if (!input.number) {
        //     setNumberError("Number is required")
        // } else if (input.number.match(phoneno)) {
        //     setNumberError("")
        // } else {
        //     setNumberError("Please enter valid number")
        //     return true
        // }
        // ================ subject =============================
        if (!input.subject) {
            setSubjectError("Subject is required");
            setTimeout(() => {
                setLoader(false)
            }, 100)
            return true;
        } else {
            setSubjectError("");
        }
        // // ================ Message =============================
        const messageId = document.getElementById("message_Id").value;
        if (!messageId) {
            setMessage("Message is required");
            setTimeout(() => {
                setLoader(false)
            }, 100)
            return true;
        } else {
            setMessage("");
        }
        // ======================== concat number and dialingCode ==============================
        if (input.number != "") {
            var mobilesData = document.getElementById("mobile").value;
            var concatData = mobilesData + input.number;
        } else {
            concatData = ""
        }
        // console.log("mobilesData", mobilesData);
        // ======================== concat number and dialingCode ==============================
        const payload = {
            dialingCode: mobilesData,
            contactNumber: concatData,
            fullName: input.name,
            emailId: input.email,
            message: input.message,
            subject: input.subject
        }
        var formdata = new FormData();
        formdata.append('get_contact_detail', JSON.stringify(payload));
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}contact_us/`,
            data: formdata,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => {
            if (res) {
                setInput({
                    name: "",
                    email: "",
                    message: "",
                    number: "",
                    subject: ""
                })
                setLoader(false);
                setNumberError("")
            }
            document.body.style.overflow = "hidden"
            seSuccess(true);
            setTimeout(() => {
                document.body.style.overflow = "auto"
                seSuccess(false)
            }, 3000)
        }).catch(err => {
            setLoader(false)
            console.log("err", err);
            var numErr = JSON.parse(err.request.response);
            if (numErr.response === "Phone number is not valid!") {
                setNumberError("Phone number is not valid!")
                setTimeout(() => {
                    setLoader(false)
                }, 100)
                return true
            } else {
                setNumberError("")
            }
        })

    }
    // ====================================== popup validations ===================================
    // ========================= Country Code =============================
    async function countryCode() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}get_country_dialing_code/`);
            const apiData = api.data.response.country_dialing_code;
            setCountryCodeData(apiData)
        } catch (error) {
            setError(true)
        }
    }

    useEffect(() => {
        countryCode()
    }, [])



    const content = useRef(null)
    useEffect(() => {
        content.current.innerHTML = DescriptionData.content
    }, [DescriptionData])


    return (
        <>
            <section className='description_section'>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <div ref={content} className='description_area'></div>
                        </Col>
                        <Col lg={4}>
                            <div className='hire_form_wrap'>
                                <BannerForm />
                            </div>
                            {/* <div className='contact_us_section'>
                                <div className='hire_dev_contact_us'>
                                    <form>
                                        <div className="user-box">
                                            <label className='w-100'>Full Name</label>
                                            <input className='w-100' type="text" name="name" placeholder='name' value={input.name} onChange={handleChange} />
                                            <small className='w-100' style={{ color: "red", fontSize: "12px" }}>{nameError}</small>
                                        </div>
                                        <div className="user-box">
                                            <label className='w-100'>Email</label>
                                            <input className='w-100' type="email" name="email" placeholder='your email' value={input.email} onChange={handleChange} />
                                            <small className='w-100' style={{ color: "red", fontSize: "12px" }}>{emailError}</small>
                                        </div>
                                        <Form.Group className="">
                                            <label className='w-100 phone_label_text'>Phone Number (optional)</label>
                                            <div className='mobile_div w-100'>
                                                <Form.Select id='mobile' >
                                                    {Error ?
                                                        <option>00</option>
                                                        : countryCodeData.map((e, key) => {

                                                            return <option key={key} value={e.Dial}>{e.country_with_dialing_code}</option>
                                                        })}
                                                </Form.Select>
                                                <Form.Control type="number" min={0} id="userNumber" placeholder="Enter number" className='input_field' name="number" value={input.number} onChange={handleChange} />
                                            </div>
                                            <small className='w-100' style={{ color: "red", fontSize: "12px" }}>{numberError}</small>
                                        </Form.Group>
                                        <div className="user-box">
                                            <label className='w-100'>Subject</label>
                                            <input className='w-100' placeholder='subject' type="text" name="subject" value={input.subject} onChange={handleChange} />
                                            <small className='w-100' style={{ color: "red", fontSize: "12px" }}>{subjectError}</small>
                                        </div>
                                        <Form.Group>
                                            <label className='w-100 phone_label_text'>Message</label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: '100px' }}
                                                className='input_field w-100'
                                                name='message'
                                                value={input.message} onChange={handleChange} id="message_Id"

                                            />
                                            <small className='w-100' style={{ color: "red", fontSize: "12px" }}>{messageError}</small>
                                        </Form.Group>
                                        <a type='button' onClick={handleSubmit}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {
                                                loader ? <div className="send_form_btn" disabled> <Spinner className='' size='sm' animation="border" variant="light" /></div> : <div className='send_form_btn'>Send</div>
                                            }
                                        </a>
                                    </form>
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
