import React from 'react'
import LaunchPlatform from '../../pages/landingPages/LaunchPlatform'

export default function Services() {
    return (
        <>
            <section className='services_new'>
                <LaunchPlatform />
            </section>
        </>
    )
}
