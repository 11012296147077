export const InsightsMenus = [
    // ================================== Insights  ==================================
    {
        type: "Insights",
        list: "Blog",
        slug: "/blog",
    },
    {
        type: "Insights",
        list: "Case Studies",
        slug: "/case-studies",
    },
    {
        type: "Insights",
        list: "Engagement Models",
        slug: "/engagement-models",
    }
]