import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Why Should You Choose Infograins to Develop Ionic Apps?</h2>
                                <div className='description'>We have a successful track record creating Ionic apps. Here are some further details on why we are your go-to Ionic app development company:</div>
                                <div className='description'>,	Transparency
                                    ,	Delivery On-Time
                                    ,	Direct Contact With Your Team
                                    ,	Deliver Exactly What You Need
                                    ,	Enhancing User Experience
                                    ,	Flexible Engagement Models
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies