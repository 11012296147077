import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Define",
            content: " Begin development by defining all system requirements, making early technological decisions, and documenting user roles and personas."
        },
        {
            title: "Design",
            content: "Determine the initial appearance and feel of your product, model the user experience and user journey, prioritize features, and plan for release."
        },
        {
            title: "Deliver",
            content: "Proceed with detailed, development-ready specifications, time and cost estimates, a launch plan, and a Toptal team ready to execute your plan."
        },
        {
            title: "Develop",
            content: "Using the project management platform of your choice, track quality assurance, DevOps, and deployment updates throughout the product development phase."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our Process</h2>
                                {/* <p>{head.description}</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
