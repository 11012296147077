import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                {/* <h2 className='h2_title'>Top</h2> */}
                <h3 className='h3_title'>Best Blockchain Development Company in India | Hire Blockchain Developers in India</h3>
                <p className='p_blockchain_dev'>Our enterprise-grade blockchain solutions help businesses streamline operations, enhance data security, and improve traceability, supporting you in staying competitive and efficient in today’s market. Infograins has emerged as one of the Best Blockchain Development Company in India where we are providing cutting-edge Blockchain Solutions to our Clients as per their requirements..</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>``
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection