import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Open-Source',
        para: 'The fact that Ionic is an open-source and subscription-free framework is a key benefit. It prides itself on being an extremely reliable platform.'
    },
    {
        title: 'Code Run Once on Any Platform',
        para: 'The Ionic framework enables seamless cross-platform operation of your app, giving consumers a native-like experience.'
    },
    {
        title: 'Angular Base By Default',
        para: 'The default use of AngularJS in Ionic for building strong applications has the advantage of expanding HTML syntax to include elements of your app.'
    },
    {
        title: 'Alluring UI',
        para: 'Because they are aware that users are drawn to attractive apps, the Ionic framework has high standards for the user interface and provides a wide range of possibilities for creating an interactive UI.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Process in Flow</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card