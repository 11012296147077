import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>The Most Rapidly Growing Mobile App Design Services</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Customers of mobile apps do not simply "point-click-buy" using your apps. As a firm, you must inspire them with your brand values and create experiences that they begin to like. To meet the mobile app design goals, we work on a design approach that is creative, user-centric, authorized, and systematic. Our design complements user mindsets and increases the brand connection.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
