import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Vision from '../../../assets/images/venturWithCDN/assset-03.png'
export default function SameVision() {
    return (
        <>
            <section className='SameVision'>
                <div className='circles'></div>
                <div className='circles'></div>
                <div className='circles'></div>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="imageWrapper">
                                <Image fluid src={Vision} />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="wrapper">
                                <div className="contentDiv">
                                    <h2>Heading Towards The Path Of Innovation</h2>
                                    <p>The inclusion of Infograins' blockchain services in CDN's already expansive suite of transformative services will be valuable to clients across the globe. Furthermore, the talented employee pool of Infograins will not only be complementary to CDN's team but will also be able to explore new opportunities and expand their knowledge base. By combining our strengths, we can offer a comprehensive range of solutions that meet the evolving needs of our customers. We look forward to driving innovation and providing enhanced value to our valuable clients. </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
