import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title_technology'>Why Choose Infograins as Your Blockchain Development Company in India?</h2>
                                <div className='description'>Choosing Infograins as your blockchain development Company in India means collaborating with one of India’s leading innovators in blockchain technology.  Infograins offers a comprehensive suite of blockchain development services designed to transform how businesses operate. With over a decade of IT experience and deep expertise in decentralized technologies, we bring unmatched precision and security to our blockchain projects.
                                </div>
                                <div className='description'>Our team of skilled developers brings years of expertise in developing blockchain solutions for diverse needs, from smart contracts and dApps to NFT marketplaces and DeFi platforms. We  leverage cutting-edge technology to transform your ideas into scalable and robust blockchain applications that align with your business goals.
                                </div>
                                {/* <ul>
                                    <li> 360° Holistic Approach</li>known
                                    <li> Dynamic Engagement Model</li>
                                    <li> Client Participation at Each Stage</li>
                                    <li> Use of Latest Technologies</li>
                                    <li> Center of Excellence and Research Team</li>
                                    <li> Agile and Lean Project Execution Approach</li>
                                </ul> */}
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies