import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Web App Development with CodeIgniter',
        content: 'CI is commonly used to build scalable web projects. We specialize in providing creative CodeIgniter application development services that aid in the growth of your organization.'
    },
    {
        title:'eCommerce Development with CodeIgniter',
        content: 'Using the CI framework, we want to create feature-rich eCommerce websites and web apps for your company. We create strong and interesting e-stores based on your specifications.'
    },
    {
        title:'Custom Web Development with CodeIgniter',
        content: 'We have a dedicated team of CodeIgniter developers who create elegant and appealing custom web development solutions depending on your company\'s specific needs.'
    },
    {
        title:'Extension Development with CodeIgniter',
        content: 'We understand the importance of feature-rich websites and web apps for your company. Our CI professionals work hard to make your websites and web apps stand out.'
    },
    {
        title:'CodeIgniter Maintenance and Support',
        content: 'Our maintenance and support team is available around the clock to assist you with any post-development concerns. They ensure that your web application functions smoothly.'
    },
    {
        title:'CodeIgniter Migration Solutions',
        content: 'We have qualified specialists who have made it simple to migrate between CodeIgniter platforms. You may engage a CodeIgniter developer who specializes in this area.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>OUR CODEIGNITER DEVELOPMENT SERVICES</h2>
                                <p>Infograins provides a comprehensive range of services based on CI components that are ideal for all types of businesses.</p>
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
