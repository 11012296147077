import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top IOT Testing Services</h2>
                                <p>Use our QA expertise to test your IoT-enabled applications for smooth operation and functioning in the complicated world of IoT devices, where specialist skills and experience are essential. With our IoT assurance solution, you can stay ahead of the competition.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
