import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BannerFormForBlog from './BannerFormForBlog'
export default function SingleBlog(props) {
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [])
    // =========================== scroll To Top default =========================
    const location = useLocation();
    const redirect = useNavigate()
    const filterApi_PathName = location.pathname.slice(1);
    // ========================= Single Blog api ================================
    const [blogContent, setBlogContent] = useState({})
    const [loader, setLoader] = useState(true);
    const blog_content = useRef(null)
    const [listData, setListData] = useState([])
    async function singleBlog() {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}${filterApi_PathName}`);
            setLoader(false)
            setListData(api.data.blog_list)
            setBlogContent(api.data.response)
            blog_content.current.innerHTML = `${api.data.response.content}`
        } catch {
            setLoader(false)
        }
    }
    useEffect(() => {
        singleBlog()
    }, [filterApi_PathName])

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    return (
        <>

            <section className='single_blog_page'>
                <Container>
                    <Row>
                        <Col lg={3}>
                            <div className='side_menu_div'>
                                <div className='side_menu'>
                                    <h4 className='h4_title'>You May Also Like This Blogs</h4>
                                    <ul>
                                        {listData.map((list, key) => {
                                            return <li key={key}>
                                                <Link to={list.blog_url}>{list.title}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className='blog_body'>
                                <h2 className='h2_title'>{blogContent.title}</h2>
                                <img className='blog_img' src={blogContent.image} alt="" />
                                <div ref={blog_content} className="blog_content_div"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>



            <Modal show={show} onHide={handleClose} centered className='blog_popup_contactus'>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pop_up_schedule_form'>
                        <BannerFormForBlog handleClose={handleClose}/>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}
