import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Web App Development Company</h2>
                                <p>Rapid business expansion necessitates the adoption of a platform that can reach users across all platforms. Demands have shifted to new trends, and fully functional online applications are now a reality. Whether you're a startup or a large corporation, our web app development services are designed to provide you a competitive advantage. Inquire about obtaining a fast, secure, and fully functional web app.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
