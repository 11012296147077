import React from 'react';
// import '../Style/Componentscss/Herosection.css';
import BannerForm from '../../common/BannerForm'
function Herosection() {
    return (
        <>
            <div className='Herosection_Hyderabad'>
                <div className='Herosec_Backimg'>
                </div>
                <div className='Herosec_Bothcontent'>
                    <div className='Herosec_Content'>
                        {/* <div className='Content_Web'>
                            <h2>Web3 Development</h2>
                        </div> */}
                        <div className='Content_Blockchain'>
                            <h1>Infograins Opens New Office in Hyderabad</h1>
                        </div>
                        <div className='Content_Webpara'>
                            <p>As a result of continuous growth and development since the inception of Infograins Software Solutions, we are proud to officially announce the opening of our new office in Hyderabad. The new office will allow us better serve our clients and tap into the growing demands for blockchain solutions.</p>
                        </div>
                    </div>
                    <div className='Herosec_Form'>
                        <BannerForm/>
                        {/* <form className='Form_Head'>
                            <div className='Form_Heading'>
                                <h3>Talk to our experts</h3>
                            </div>
                            <div className='Form_Name'>
                                <input type='text' placeholder='Enter name' />
                            </div>
                            <div className='Form_Email'>
                                <input type='text' placeholder='Enter email' />
                            </div>
                            <div className='Form_Subject'>
                                <input type='text' placeholder='Enter subject' />
                            </div>
                            <div className='Form_Number'>
                                <label>IND +91</label>
                                <input type='text' placeholder='Enter number (optional)' />
                            </div>
                            <div className='Form_line'>
                                <hr />
                            </div>
                            <div className='Form_Submit'>
                                <input type='submit' value='Send' />
                            </div>
                        </form> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Herosection;