import React from 'react'
import { Container, Row } from "react-bootstrap"
import NeWImg1 from '../../../assets/media/Travel-Tourism.svg'
import NeWImg2 from '../../../assets/media/Healthcare.svg'
import NeWImg3 from '../../../assets/media/real-estate.svg'
import NeWImg4 from '../../../assets/media/energy.svg'
import NeWImg5 from '../../../assets/media/retail.svg'
import NeWImg6 from '../../../assets/media/Education.svg'
import CommonCard from './common-card'
const ApiData = [
    {
        image: NeWImg1,
        title: 'Aviation',
        para: 'Transform aviation operations with blockchain Services for secure passenger identity verification, efficient ticketing, real-time tracking of luggage, and tamper-proof maintenance logs to enhance safety and trust.'
    },
    {
        image: NeWImg2,
        title: 'Healthcare',
        para: 'For secure and efficient data management, we implement blockchain solutions that protect patient data, enable safe data sharing, and support transparent medical record management, enhancing trust and efficiency across healthcare ecosystems.'
    },
    {
        image: NeWImg3,
        title: 'Real Estate',
        para: 'Our blockchain platforms facilitate smoother transactions by creating immutable digital property records, enabling secure, transparent ownership, and streamlining property sales and title transfers.'
    },
    {
        image: NeWImg4,
        title: 'Energy and Utilities',
        para: 'We empower the energy sector with blockchain solutions for energy trading, renewable energy certification, and efficient grid management, supporting sustainability and decentralized energy initiatives.'
    },
    {
        image: NeWImg5,
        title: 'Retail and E-commerce',
        para: 'Infograins develops blockchain applications that authenticate product origins, secure transactions, and offer loyalty rewards, fostering trust between retailers and customers while ensuring seamless, secure operations.'
    },
    {
        image: NeWImg6,
        title: 'Education',
        para: 'Our blockchain solutions in education ensure secure credentialing and transparent student records, enabling academic institutions to authenticate achievements and support decentralized learning platforms.'
    }
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Industries We Serve</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                            {ApiData.map(({ title, para, image }, index) => {
                                    return <CommonCard key={index} title={title} para={para} image={image} index={index} type={"industries-serve"} />
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card