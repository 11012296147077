import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Wireframing and Prototyping Development Company</h2>
                                <p>Infograins wireframe and prototyping services for businesses aid in the facilitation and acceleration of development time. Our interface development services enable you to create an MVP quickly and demonstrate your investors your startup's full potential.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
