import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import joyStick from "../../../assets/images/background/games/joystick.png"

const GameSolution = () => {
    return (
        <>
            <section className='GameSolution-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}><div className='GameSolution-text'>
                            {/* <h3 className='h3_title'>subheading</h3> */}
                            {/* <h2 className='h2_title'>title</h2> */}
                            <p>IoT prototyping is the process of testing and putting plan concepts into rough drafts of a finished good. The development of hardware prototypes and sensor prototypes is assisted. It is the very first step in the process of making an IoT product. The gadget should be connected to every project as well. The IoT Hardware prototype is difficult, though, as it calls for cutting-edge technology and IoT-savvy programmers. However, the technology supports sensors that collect useful data and aid in product analysis. Devices can connect and do tasks thanks to it. The business requires highly qualified professionals and cutting-edge technologies for IoT hardware-based goods. It requires someone who can carry out the lengthy process without any hiccups or mistakes.</p>
                            <p>A system's connectivity, backend software, and IoT hardware prototypes are separated. So, various techniques are used to prototype IoT hardware, and each technique has advantages. It allows for the acquisition of insights and the resolution of problems with astounding prospects. Building an IoT prototype is done to test the idea and make sure it appeals to your target market. The process of meeting expectations becomes simple, which facilitates business validation.</p>
                            <div className="center">
                                <a href="#"><span data-attr="Click">Click</span><span data-attr="Me">Me</span></a>
                            </div>
                        </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='GameSolution-img'>
                                <Image src={joyStick} alt='GameSolution' fluid />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameSolution