import React from 'react'
import { Container, Row } from "react-bootstrap"
import NeWImg1 from '../../../assets/media/Healthcare.svg'
import NeWImg2 from '../../../assets/media/Finance.svg'
import NeWImg3 from '../../../assets/media/retail.svg'
import NeWImg4 from '../../../assets/media/Manufacturing.svg'
import NeWImg5 from '../../../assets/media/supply-chain-icon.svg'
import NeWImg6 from '../../../assets/media/Education.svg'
import CommonCard from '../blockchain_development/common-card'


const ApiData = [
    {
        image: NeWImg1,
        title: 'Healthcare',
        para: 'Revolutionize patient care with AI-powered solutions for medical imaging, disease prediction, and personalized treatment plans, ensuring enhanced diagnostic accuracy and better health outcomes.'
    },
    {
        image: NeWImg2,
        title: 'Finance',
        para: 'Enhance financial security with AI tools that streamline fraud detection, automate reporting, and improve risk assessment, enabling smarter financial decisions and increased efficiency.'
    },
    {
        image: NeWImg3,
        title: 'Retail & E-commerce',
        para: 'Boost customer engagement and revenue with AI solutions for personalized product recommendations, trend analysis, dynamic pricing, and efficient inventory management.'
    },
    {
        image: NeWImg4,
        title: 'Manufacturing',
        para: 'Improve production quality and reduce downtime with AI-driven predictive maintenance, process optimization, and real-time defect detection to streamline manufacturing operations.'
    },
    {
        image: NeWImg5,
        title: 'Logistics & Supply Chain',
        para: 'Optimize operations with AI tools for demand forecasting, intelligent route planning, and supply chain transparency, ensuring cost efficiency and on-time deliveries.'
    },
    {
        image: NeWImg6,
        title: 'Education & E-learning',
        para: 'Transform learning experiences with AI-powered adaptive learning platforms, automated grading systems, and personalized course recommendations to meet diverse educational needs.'
    }
]
const IndustriesServe = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Industries We Serve</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                            {ApiData.map(({ title, para, image }, index) => {
                                    return <CommonCard key={index} title={title} para={para} image={image} index={index} type={"artificial-intelligence"} />
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default IndustriesServe