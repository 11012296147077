import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Detection of Special Objects',
        content: 'Every object is recognized, and you can later create your own objects. The visual can be identified and defined by this detection, allowing for more specialized and unique uses.'
    },
    {
        title: 'Facial Search Functions',
        content: 'The task is simple and efficient thanks to the facial search capabilities. Reorganizing objections has become more dynamic because to the capacity to search based on a person\'s expression or gaze.'
    },
    {
        title: 'Verification by User',
        content: 'Let\'s say someone has to verify a few little information. In that situation, object identification helps make it more accessible, puts things in motion immediately, and speeds up the drawn-out procedure.'
    },
    {
        title: 'Personalization',
        content: 'Personalization in facial practices, including image, video, and much more, is made possible by object recognition. Users and customers can quickly do searches using the graphic.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Advantages of Object Recognition:</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements