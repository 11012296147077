import React, { useState, useEffect, useRef } from "react";
import "../../assets/style/common/footer.scss";
import { BiUpArrowAlt } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsSkype } from "react-icons/bs";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import WhatsApp from "../../assets/media/whatsapp.webp";
import Address from "../pages/contactUs/Address";
import Brochure from "./brochure.pdf";
import TB_Logo from "../../assets/images/tb_logo.svg";
import CDNLogo from "../../assets/media/cdn-logo.png";

import flexihublogo from "../../assets/images/our-group-companies/FLEXIHUB LOGO.svg";
import InfograinsIT from "../../assets/images/our-group-companies/Infograins IT.svg";
import InfograinsTcs from "../../assets/images/our-group-companies/TCS Infograins LOGO.svg";
import Sliceledger from "../../assets/images/our-group-companies/Sliceledger logo.svg";
import bharatnft from "../../assets/images/our-group-companies/Bharat NFT logo.svg";
import blockchaninmaker from "../../assets/images/our-group-companies/Blockchain maker logo.svg";
import rentacoder from "../../assets/images/our-group-companies/Renta Coders logo.svg";
import seosquad from "../../assets/images/our-group-companies/seosquad.png";
import tmad from "../../assets/images/our-group-companies/TMAD logo.svg";
import Licit360 from "../../assets/images/our-group-companies/Licit360 Logo.svg";
import Infosense from "../../assets/images/our-group-companies/Infosense logo.svg";
import Codeready from "../../assets/images/our-group-companies/Codeready Logo.svg";

const Footer = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  });

  const handleTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // =============================== company api ================================
  // const [companyData, setCompanyData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  // const [aboutData, setAboutData] = useState([]);
  const [resourcesData, setResourcesData] = useState([]);
  const [ErrorCompany, setErrorCompany] = useState(false);
  // ============================ service =================================
  async function serviceApi() {
    try {
      const api = await axios.get(
        `${process.env.REACT_APP_BASE_URL}blockchain/blockchain_list/`
      );
      setServiceData(api.data.response[0].array_of_blockchain_category_list);
    } catch (error) {
      setServiceData(true);
    }
  }
  // ============================ service =================================

  async function footerApi() {
    try {
      const api = await axios.get(
        `${process.env.REACT_APP_BASE_URL}get-all-models/`
      );
      // setCompanyData(api.data.response.Company)
      setServiceData(api.data.response.Services);
      // setAboutData(api.data.response.About)
    } catch (error) {
      setErrorCompany(true);
    }
  }
  const resources = async () => {
    try {
      const url = await axios.get(
        `${process.env.REACT_APP_BASE_URL}resource/resources_list/`
      );
      setResourcesData(url.data.response);
    } catch (error) {
      setErrorCompany(true);
    }
  };
  useEffect(() => {
    footerApi();
    resources();
    serviceApi();
  }, []);
  const navAbout = useNavigate();
  const handleNav = () => {
    navAbout("/aboutUs");
  };
  // =========================================================================
  const dragRef = useRef(null);
  function Over(e) {
    dragRef.current.style.top = e.pageY + "px";
    dragRef.current.style.left = e.pageX + "px";
  }

  useEffect(() => {
    window.document.body.addEventListener("mousemove", Over);
  }, []);

  const companies = [
    {
      src: InfograinsIT,
      alt: "Infograins",
      href: "https://infograins.com/",
    },
    { src: Infosense, alt: "InfoSense", href: "https://infosense.tech/" },
    {
      src: blockchaninmaker,
      alt: "Blockchain App Maker",
      href: "https://blockchainappmaker.com/",
    },
    { src: Sliceledger, alt: "SliceLedger", href: "https://sliceledger.io/" },
    {
      src: Codeready,
      alt: "CodeReady",
      href: "https://codereadysoftware.com/",
    },
    { src: seosquad, alt: "SEO Squad", href: "https://seosquad.co/" },
    { src: Licit360, alt: "Licit360", href: "https://licit360.in/" },
    {
      src: InfograinsTcs,
      alt: "InfograinsTCS",
      href: "https://infograinstcs.com/",
    },
    { src: tmad, alt: "TMAD", href: "https://topmobileappsdevelopment.com/" },
    { src: rentacoder, alt: "RentACoder", href: "http://rentacoders.com/" },
    { src: bharatnft, alt: "BharatNFT", href: "https://bharatnft.shop/" },
    { src: flexihublogo, alt: "FlexiHub", href: "https://flexi-hub.com/" },
  ];
  

  return (
    <>
      <div className="cursor-web" ref={dragRef}>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="12" stroke="#F48533" strokeWidth="2" />
        </svg>
      </div>

      <div className="whats_app_div">
        <a
          href="https://wa.me/+919770477239"
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={WhatsApp} alt="whatsapp" loading="lazy" />
        </a>
      </div>

      <div
        className="top-btn-wrap"
        onClick={handleTop}
        style={showTopBtn === false ? { display: "none" } : { display: "flex" }}
      >
        <button className="btn">
          <BiUpArrowAlt />
        </button>
      </div>
      <section className="footer_section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="r_group_container">
                <h2>Our Group of Companies</h2>
                <div className="r_group_logos">
                  {companies.map((company, index) => (
                    <a
                      key={index}
                      href={company.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={company.src}
                        alt={company.alt}
                        className="logo"
                      />
                    </a>
                  ))}
                </div>
              </div>
              <div className="footer_head">
                <div className="subscribe_fieldDiv">
                  <div className="subscribe_text">Subscribe for Updates</div>
                  <div className="subscribe_email_field">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="search_email"
                    />
                    <button>
                      <BiRightArrowAlt />
                    </button>
                  </div>
                </div>
                <div className="social_media_links">
                  <a
                    href="https://www.facebook.com/infograins/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://twitter.com/infograinssoft?s=20"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.instagram.com/infograins/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/infograin-software-solutions/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                  <a
                    href="https://join.skype.com/invite/NqBQ11qKBCxI"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <BsSkype />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={3}>
              <div className='foot_sec'>
                <div className='foot_sec_subhead'>Resources</div>
                <ul>
                  {
                    resourcesData.map((ele, key) => {
                      return (
                        <li key={key}><Link to={ele.resource_url}>{ele.name}</Link></li>
                      )
                    })
                  }
                  <li><a href={Brochure} target={'_blank'} rel='noreferrer'>Our Brochure</a></li>
                </ul>
              </div>
            </Col> */}
            <Col lg={4}>
              <div className="foot_sec">
                <div className="foot_sec_subhead">Company</div>
                <ul>
                  {/* <li>
                    <Link to="/team">Team</Link>
                  </li> */}
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                  <li>
                    <Link to="/">Event</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/term-and-condition">Term and Condition</Link>
                  </li>
                  <li>
                    <a href={Brochure} target={"_blank"} rel="noreferrer">
                      Our Brochure
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="foot_sec_one"></div>
              <div className="foot_sec">
                <div className="foot_sec_subhead">Services</div>
                <ul>
                  <li>
                    <Link to={"/blockchain-development"}>Blockchain</Link>
                  </li>
                  <li>
                    <Link to={"/mobile-development"}>Mobile Development</Link>
                  </li>
                  <li>
                    <Link to={"/web-development"}>Web Development</Link>
                  </li>
                  <li>
                    <Link to={"/design"}>Design</Link>
                  </li>
                  <li>
                    <Link to={"/ai-development-services"}>AI Development</Link>
                  </li>
                  <li>
                    <Link to={"/software-testing"}>Software Testing</Link>
                  </li>
                  <li>
                    <Link to={"/game-development"}>Game Development</Link>
                  </li>
                  <li>
                    <Link to={"/robotics"}>Robotics</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="foot_sec">
                <div className="foot_sec_subhead">About</div>
                <ul>
                  <li>
                    {" "}
                    <Link to="/aboutUs">About Company</Link>
                  </li>
                  <li>
                    {" "}
                    <a href="#mission" onClick={handleNav}>
                      Vision & Mission
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#ourPartner" onClick={handleNav}>
                      Become Our Partner
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#technology" onClick={handleNav}>
                      Our Technology Partners
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <hr />
        </Container>
        <div className="footer_address">
          <Address>
            <div className="clt_and_gfr_img_wrap mt-5">
              <div className="bottom_logos">
                <div className="clt_and_gfr_img">
                  <div
                    className="clutch-widget"
                    data-url="https://widget.clutch.co"
                    data-widget-type="2"
                    data-height="45"
                    data-nofollow="true"
                    data-expandifr="true"
                    data-scale="100"
                    data-clutchcompany-id="458978"
                  ></div>

                  <div className="tb_logo mt-5">
                    <a
                      href="https://techbehemoths.com/company/infograins-software-solutions-pvt-ltd"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={TB_Logo}
                        alt="tb_logo"
                        className="img-fluid"
                        loading="lazy"
                        style={{ width: "165px" }}
                      />
                    </a>
                  </div>
                </div>
                <div>
                  {/* <a href="https://topsoftwarecompanies.co/blockchain-development/agencies/india" target="_blank">
                    <img src="https://topsoftwarecompanies.co/badges/top-software-developers.png" alt="blockchain developers india" title="Blockchain Developers India" className='d-block mx-auto mb-3' style={{ width: "150px" }} />
                  </a> */}
                  <div
                    className="goodfirm-widget"
                    data-widget-type="goodfirms-widget-t1"
                    data-height="198"
                    data-company-id="7780"
                  ></div>
                </div>
              </div>
            </div>
          </Address>
        </div>
      </section>
      <footer className="app-footer">
        <Container>
          {/* <div className='clt_and_gfr_img_wrap'>
            <Row>
              <Col sm={6} md={6} lg={6} xl={6}>
               <div className='bottom_logos'>
               <div className="clt_and_gfr_img">
                  <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="458978"></div>
                </div>
                <div className="goodfirm-widget" data-widget-type="goodfirms-widget-t1" data-height="198" data-company-id="7780"></div>
               </div>
              </Col>
            </Row>
          </div> */}
          <Row>
            <Col lg={12}>
              <div className="bottom_footer my-2">
                <a
                  href="//www.dmca.com/Protection/Status.aspx?id=064d3eba-ec5d-411c-80de-5f48aa985d6e"
                  title="DMCA.com Protection Status"
                  className="dmca-badge"
                >
                  <img
                    src="//images.dmca.com/Badges/DMCA_logo-grn-btn120w.png?ID=064d3eba-ec5d-411c-80de-5f48aa985d6e"
                    alt="DMCA.com Protection Status"
                    loading="lazy"
                  />
                </a>
                {/* <div className='cdn_class'>
                  <h4>Part of <br></br><span>CDN Solutions Group</span></h4>
                  <a href="https://www.cdnsol.com/" target="_blank" rel='noreferrer'
                    title="DMCA.com Protection Status" className="dmca-badge">
                    <img src={CDNLogo} style={{ width: "200px" }}
                      alt="DMCA.com Protection Status" loading='lazy' />
                  </a>
                </div> */}
              </div>
            </Col>
            <Col lg={12}>
              <p>Copyright©2022 | All right reserved</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
