import React, { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';

export default function Work({ data }) {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 4,

            }
        },
    };

    return (
        <>
            <section className='work_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="head">
                                <h2>Why do Businesses Choose Infograins?</h2>
                            </div>
                        </Col>
                        <Col lg={12}>
                            {/* {data ?
                                <OwlCarousel className='owl-theme hero_slider' loop margin={10} {...options}>
                                    {data ? data.map(({ title, content, id }) => {
                                        return <div key={id} className='item'>
                                            <div className='step_div'>
                                                <div className="step">{id}</div>
                                                <h4>{title}</h4>
                                                <p>{content}</p>
                                            </div>
                                        </div>
                                    }) : ''}
                                </OwlCarousel>
                                : ''} */}

                            <OwlCarousel className='owl-theme hero_slider' loop margin={10} {...options}>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">1</div>
                                        <h4>Industry-Specific Experience</h4>
                                        <p>We have a dedicated team for each vertical. Every team follows best practices and trends specific to the vertical. Infograins provide insights that add value to your project.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">2</div>
                                        <h4>Communication</h4>
                                        <p>We ensure that there is no lack of communication between our team and the client. Our dedicated team can work according to your time zone. We also provide regular progress reports to our clients.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">3</div>
                                        <h4>Support</h4>
                                        <p>Our support team is available to you 24*7. You can ask technical queries and our technical team will assist you. You will also get the best possible solution from our support team.</p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='step_div'>
                                        <div className="step">4</div>
                                        <h4>Ownership of Source Code</h4>
                                        <p>Our transparent policies keep the client and our team on the same page. Therefore, we insist that our clients own their source code.</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
