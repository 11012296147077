import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Comprehensive Strategy",
            content: "Custom-made testing strategies covering functionality, performance, security, and interoperability."
        },
        {
            title: "Device Compatibility",
            content: "Rigorous testing to ensure all devices work flawlessly together."
        },
        {
            title: "Security Focus",
            content: "Advanced methods to safeguard data integrity and protect against vulnerabilities."
        },
        {
            title: "Scalability Assurance",
            content: " Testing for smooth scaling as your IoT ecosystem grows."
        },
        {
            title: "Real-World Simulation",
            content: "Replicating real-world scenarios for performance assessment."
        },
        {
            title: "Continuous Testing",
            content: "Ongoing testing and monitoring to keep your solution up-to-date and secure."
        },
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                {/* <h2 className='h2_title'>Our Services for Full-Stack Web App Development</h2> */}
                                <p>At Infograins we've create an efficient IoT testing approach to guarantee your IoT solutions' connectivity and reliability. Here's how we do it</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
