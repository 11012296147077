import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Let's Enhance Your eCommerce Business</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Ready to take your eCommerce business to new heights? Partner with Infograins for comprehensive eCommerce web and app testing services. Our expertise, dedication, and commitment to excellence will ensure your online store remains competitive, secure, and user-friendly.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
