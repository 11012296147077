import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Mobile App Testing Services</h2>
                                <p>In the ever-changing world of mobile apps, ensuring your app's flawless functionality, security, and user experience is critical. At Infograins, we specialize in comprehensive mobile app testing services that ensure your app's success in a competitive digital landscape. Our high-performing testing engineers validate all areas of your mobile app within optimal testing time and budget.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
