import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top JavaScript Development Company</h2>
                                <p>JavaScript, the world's most popular programming language, is widely utilized for web development as well as a variety of other projects ranging from mobile applications to games. While finding a developer who understands the language is not difficult, finding quality JavaScript development services is. Fortunately, if you know what to look for, you can find the ideal people for your company.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
