import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Responsive web design',
        content: 'We specialize in creating responsive web designs.'
    },
    {
        title:'Custom Web Development',
        content: 'Our talented development team specializes in creating unique web solutions.'
    },
    {
        title:'Ecommerce platform development',
        content: 'Our developers can assist you in developing an e-commerce platform.'
    },
    {
        title:'Database & API development',
        content: 'Our developers are very skilled in database design and development.'
    },
    {
        title:'WordPress and Drupal',
        content: 'We have experience creating websites with WordPress and Drupal.'
    },
    {
        title:'PHP Saas development',
        content: 'Our developers can assist you in creating scalable SaaS applications.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>We handle all aspects of PHP development</h2>
                                {/* <p>We provide server-side application development services utilizing Next.js, one of the quickest JavaScript frameworks, as a reputable NextJS development firm. Our developers leverage Next.js' capabilities to build applications that are feature-rich, quick, and suited to your specific business needs. Infograins is dedicated to provide high-quality NextJS development services. Hiring a NextJS developer will ensure that the total process is smooth, high-performing, and scalable.</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
