import React from 'react';
// import '../Style/Componentscss/Blocksection.css';

function Blocks() {
    return (
        <>
            <div className='Block_Body'>
                <div className='Block_Image'>
                    <div className='Block_Img'></div>
                </div>
                <div className='Block_Content'>
                    <div className='Block_Heading'>
                        <h3>Why Hyderabad?</h3>
                    </div>
                    <div className='Block_Para'>
                        <p>Hyderabad has been making waves in the tech industry for its impressive strides in innovation. This vibrant city has emerged as a hub of exceptional blockchain solutions. With its highly skilled workforce and supportive business community, the city offers us the ideal environment to deliver top-notch blockchain solutions. Hyderabad is also home to many leading tech companies, and we are delighted to be part of this vibrant community. Unmatched in its breadth and depth of expertise, Infograins hope to contribute to the growth of blockchain in the region.</p>
                    </div>
                    {/* <div className='Block_Btn'>
                        <button className='btn'><span>Get Free Consultancy</span></button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Blocks; 