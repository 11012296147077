import React from 'react'
import Herosection from './Herosection'
import Blocks from './Blocksection'
import Virtual from './Virtualworld'
import Web from './Websection'
import Services from './Services'
export default function Hyderabad() {
    return (
        <>
            <Herosection />
            <Blocks/>
            <Virtual/>
            <Web/>
            <Services/>
        </>
    )
}
