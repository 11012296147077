import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Join Forces With Us To Build Flutter-Based, Highly Scalable Apps</h2>
                                <div className='description'>We recognize how crucial it is to have a distinctive brand image that represents your company. Every iOS, Android, and web app we design with our Flutter developer is a perfect representation of the brand it stands for. As a Flutter app development firm, we make sure that all of the Material Design & Cupertino components we use in your apps are fully configurable to give your users a Native replica application on every device they use.</div>
                                <div className='description'>A sturdy architecture and expressive, flexible UI are the products of combining the feature-rich Flutter SDK with the visually stunning design component.</div>
                                <div className='description'>Because of this, Infograins is regarded as the most trustworthy and impeccable firm for developing flutter apps in the USA, the United Arab Emirates, and Europe. We have developed a number of applications that have set new benchmarks, and our flutter developer team is skilled at creating world-class apps.</div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies