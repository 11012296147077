import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import TopImg from '../../../assets/images/venturWithCDN/header-Graphic.png'
import Infograins from '../../../assets/images/venturWithCDN/logo-infograins.png'
import CDN from '../../../assets/images/venturWithCDN/CDN-logo.png'

export default function HeadSection() {
    return (
        <>
            <section className='headSection'>
                <Image fluid src={TopImg}/>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='headDiv'>
                                <h2>A Collaboration Between Two Pioneers of Success</h2>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='imageDiv'>
                                <Image fluid src={Infograins}/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='imageDiv'>
                                <Image fluid src={CDN}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
