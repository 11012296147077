import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Define',
        content: 'Start the development process by deciding on the initial technology, outlining all system requirements, and identifying user roles and personas.'
    },
    {
        title: 'Design',
        content: 'Establish the initial appearance and feel of your product, model the user experience and user journey, rank the features, and make release plans.'
    },
    {
        title: 'Deliver',
        content: 'With thorough, development-ready specifications, time and cost estimates, a launch strategy, and a team of Infograins experts prepared to carry it out, you can move forward.'
    },
    {
        title: 'Develop',
        content: 'Use your preferred project management application to keep track of quality assurance, DevOps, and deployment updates during the product development process.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Process</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements