import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Agile Techniques',
        content: 'Beginning with a deep understanding of the client\'s requirements, we use agile approaches. Through the cooperation of cross-functional teams, our strategy and development methods will continue to change. '
    },
    {
        title: 'Affordable Solutions',
        content: 'As a top provider of ionic app development services, our staff evaluates client demands and offers affordable ionic app development solutions. We offer a technology stack that will satisfy the client\'s needs and demands.'
    },
    {
        title: 'Transparency',
        content: 'We support open communication. Every procedure and code pertaining to the client\'s app is accessible whenever needed. To them, nothing is kept secret. In our ionic app development services, we inform clients of our progress at every stage of the development process.'
    },
    {
        title: 'Flexibility',
        content: 'As the best Ionic app development company, we offer flexibility in a variety of ways. We are flexible when it comes to coding, working hours, the method to be used for development, and round-the-clock support.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Our Features:</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements