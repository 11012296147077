import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Img from '../../../assets/media/new-landing/s01.png'
import { Link } from 'react-router-dom'
const KeyPointsData = [
    {
        value: '13 +',
        text: 'Years of Experience'
    },
    {
        value: '150 +',
        text: 'Development Staff'
    },
    {
        value: '75 +',
        text: 'Countries Served'
    },
    {
        value: '1100 +',
        text: 'Project Delivered'
    },
    {
        value: '85 +',
        text: 'Repeated Clients'
    },
    {
        value: '3.0M +',
        text: 'Hours of Efforts'
    },
]

export default function Section1() {
    return (
        <>
            <section className='section_one'>
                <Container>
                    <Row>
                        {KeyPointsData.map(({ text, value }, id) => (
                            <Col lg={2} md={4} sm={6} key={id}>
                                <div className='keyPoint_box'>
                                    <div className='value'>{value}</div>
                                    <div className='text'>{text}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className='align-items-center'>
                        <Col xl={7} lg={7} md={7} sm={12} className='content'>
                            <h2 className='h2'>Empowering Businesses with Custom Software Development Services</h2>
                            <p>Custom software development services play a crucial role in driving business growth by tailoring technology solutions to meet the specific needs and challenges of a company. This ensures that the software aligns perfectly with the business processes, workflows, and objectives. We provide Best Custom Software Development services to our Clients. Custom software can be designed to enhance the overall customer experience. Whether it's a user-friendly interface, personalized features, or seamless integration, a well-developed software solution contributes to customer satisfaction, loyalty, and retention.</p>
                            <div className='btn_group'>
                                {/* <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                        <path d="M14.0208 0.999936C14.0208 0.447652 13.5731 -6.33821e-05 13.0208 -6.3635e-05L4.02082 -6.35086e-05C3.46853 -6.31714e-05 3.02082 0.447652 3.02082 0.999937C3.02082 1.55222 3.46853 1.99994 4.02082 1.99994L12.0208 1.99994L12.0208 9.99994C12.0208 10.5522 12.4685 10.9999 13.0208 10.9999C13.5731 10.9999 14.0208 10.5522 14.0208 9.99994L14.0208 0.999936ZM1.70711 13.7279L13.7279 1.70704L12.3137 0.29283L0.292893 12.3136L1.70711 13.7279Z" fill="#1F2122" />
                                    </svg>
                                    Know more</button> */}
                                <Link to={'/aboutUs'}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                                            <path d="M17.7071 8.20711C18.0976 7.81658 18.0976 7.18342 17.7071 6.79289L11.3431 0.428931C10.9526 0.0384068 10.3195 0.0384069 9.92893 0.428931C9.53841 0.819456 9.53841 1.45262 9.92893 1.84314L15.5858 7.5L9.92893 13.1569C9.53841 13.5474 9.53841 14.1805 9.92893 14.5711C10.3195 14.9616 10.9526 14.9616 11.3431 14.5711L17.7071 8.20711ZM8.74228e-08 8.5L17 8.5L17 6.5L-8.74228e-08 6.5L8.74228e-08 8.5Z" fill="black" />
                                        </svg>
                                    </div>
                                    Know more
                                </Link>
                            </div>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Image className='section_one_img' src={Img} alt='image' fluid />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
