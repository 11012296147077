import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Knowledge from Multiple Domains",
            content: "We have considerable understanding across numerous industry areas due to our 10+ years of experience in the SQA market."
        },
        {
            title: "Proactive approach",
            content: "Our QA professionals are enthusiastic about every application they work on, and after completing the testing process, they provide actionable recommendations for web app enhancement."
        },
        {
            title: "Large team of Experienced QA specialists",
            content: "Get instant access to a wealth of industry knowledge. We provide a transparent and expedited candidate selection process."
        },
        {
            title: "Continuous upskilling",
            content: "The Infograins team is continuously on the lookout for new information, and we attend regular trainings and certification courses to stay up to date on the newest industry trends and practices."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why choose Infograins Web Testing Services?</h2>
                                <p>As a web application testing firm, we are involved in all stages of your product development lifecycle, ensuring the quality of your software while lowering maintenance expenses.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
