import React from 'react'
import { Col } from "react-bootstrap"

function CommonCard({ index, title, para, image, type }) {
    return (
        <Col sm={12} md={6} lg={4} xl={4}>
            <div className={`w3-card-body card_number_${index}`}>
                <img src={image} alt="image" height={50} width={50} />
                <h2 className='h2_title' style={{
                    color: "#f68633", padding: (type === "blockchain-process" && (index === 0 || index === 1))
                        ? "33px 0px"
                        : (type === "industries-serve" && (index === 0 || index === 2))
                            ? "27px 0px"
                            : (type === "artificial-intelligence" && (index === 1 || index === 2 || index === 3 || index === 4))
                                ? "27px 0px"
                                : (type === "why_choose_infograins" && (index !== 5))
                                    ? "27px 0px"
                                    : "15px 0px"
                }}>{title}</h2>
                <p>{para}</p>
            </div>
        </Col>
    )
}

export default CommonCard
