import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default function Banner() {
  return (
    <>
        <section className='engagement_banner'>
            <Container>
                <Row>
                    <Col lg={12} md={12}>
                        <div className="head">
                            <h2>Engagement Models</h2>
                            <p>Our flexible engagement model supports long term partnership with clients <br></br> from different niches.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
