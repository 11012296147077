import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
const NFTCate = [
    {
        title:'Websites',
        content:'A well-designed website is a potent marketing and communication tool that aids in fostering leads and fostering trust.'
    },
    {
        title:'Ecommerce',
        content:'Businesses can function around the clock, seven days a week, and connect with clients in any time zone thanks to eCommerce solutions.'
    },
    {
        title:'Web Applications',
        content:'A global audience can access web apps. They may easily keep users\' attention because they are interactive and captivating.'
    },
    // {
    //     title:'Content Management System',
    //     content:'By streamlining internal processes, a specialized CMS created for your company\'s needs will save time and money.'
    // },
]
export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                <h2 className='h2_title'>Achieve Your Business Goals with Web Development Solutions</h2>
                                <p>A variety of web development services are areas of expertise for our seasoned professionals. Here are a few of the services we can provide for you.</p>
                            </div>
                        </Col>
                        { NFTCate.map((e, key) => {
                                    return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                        <div className='box_shell'>
                                            <BsBox />
                                            <h5 className='h5_title'>{e.title}</h5>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
