import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import gameGirl from "../../../assets/images/trusted/aceRemove.png"

const OurGame = () => {
    const GameCate = [
        {
            title: " Connect IoT devices for smart homes and create cohesive ecosystems where each component performs to the best of its ability and promotes reliable, secure, and effective operation.",
            content: ""
        },
        {
            title: "Utilize our knowledge of big data and cloud platforms to help you select the right technological stack and tools to fulfill the future system's performance and scalability needs.",
            content: ""
        },
        {
            title: "Create an IoT system that is truly intelligent and self-contained. We add intelligence and automation to data analytics by utilizing machine learning, computer vision, and NLP technologies, and we provide users with user interfaces they genuinely like.",
            content: ""
        },
    ]
    return (
        <>
            <section className='ourGame-wrap'>
                <Container>
                    <div className='ourGame-title'>
                        {/* <h3 className='h3_title'>subheading</h3> */}
                        <h2 className='h2_title text-center'>How Infograins Can Assist You with IOT Home Automation</h2>
                    </div>
                    <Row>
                        {
                            GameCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='ourGame-card-wrap'>
                                        <figure className="ourGame-card-img">
                                            <Image src={gameGirl} alt='Ace' />
                                        </figure>
                                        <div className='ourGame-card-detail'>
                                            <h3 className='h3_title'>{e.title}</h3>
                                            <p>{e.content}</p>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurGame