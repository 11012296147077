import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoxSections from './BoxSections'
import ContentDiv from './ContentDiv'
import HeroSections from './HeroSections'
import KeyFeature from './KeyFeature'
import { Helmet } from 'react-helmet'

export default function Wordpress(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Wordpress Development Company – Infograins</title>
                <meta name="description" content="Infograins is the well-known brand as a wordpress development company. We have helped numerous clients with custom wordpress development services across the globe." />
            </Helmet>
            <HeroSections />
            <BoxSections />
            <ContentDiv />
            <KeyFeature />
        </>
    )
}
