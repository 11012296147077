import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Codeigniter Development Company</h2>
                                <p>At Infograins, we take pride in being known as one of the best CodeIgniter development firms in the industry. With a proven track record of excellence, we are your trusted partner for developing unique online and web application solutions that fulfil the needs of startups as well as large-scale corporations.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
