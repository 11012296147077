import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import GoalImg from '../../../assets/images/venturWithCDN/assset-02.png'

export default function SimilarGoal() {
    return (
        <>
            <section className='similarGoal'>
                <div className='circles'></div>
                <div className='circles'></div>
                <div className='circles'></div>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="wrapper">
                                <div className="contentDiv">
                                    <h2>A Vision Beyond Imagination</h2>
                                    <p>The collaboration will allow us to create innovative solutions that meet the unique needs of our clients and help them navigate the rapidly evolving technological landscape. We want to help clients flourish by offering robust Blockchain and Web3 solutions. Additionally, we want to give consumers business expertise and in-depth consultation by utilizing each other's strengths. Clients can look forward to a range of customized solutions that are designed to meet their specific requirements and deliver maximum value.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="imageWrapper">
                                <Image fluid src={GoalImg} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
