import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import news from "../../../assets/images/trusted/aceGame2.jpg"
const GameCate = [
    {
        title:'PC',
        content:'Game development from start to finish for personal computers with Windows or MacOS porting.'
    },
    {
        title:'Console',
        content:'Game development for all popular consoles, including Xbox, Nintendo, and PlayStation 4.'
    },
    {
        title:'Mobile',
        content:'Developing cross-platform or mobile games for iOS and Android.'
    },
    {
        title:'AR/VR',
        content:'Fascinating games are made with the use of cutting-edge virtual reality and augmented reality technologies.'
    },
]
const GameNews = () => {
    return (
        <>
            <section className='gameNews-wrap'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='text-center mb-4'>
                                {/* <h4 className='h4_title'>{HeadData.subheading}</h4> */}
                                <h2 className='h2_title'>We Develop Games For These Key Platforms</h2>
                                <p className='description'>One of our key qualities is our embracement of game creation on the most well-liked platforms and ability to attract players with a wide range of preferences.</p>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                {GameCate.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='gameNews-card-wrap'>
                                            <figure className="gameNews-card-img">
                                                <Image src={news} alt='News' fluid />
                                            </figure>
                                            <div className='gameNews-card-detail'>
                                                <h3 className='h3_title'>{e.title}</h3>
                                                <p>{e.content}</p>
                                            </div>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameNews