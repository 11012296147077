import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../../assets/style/pages/landing_page/launchPlatform.scss";
import BlockchainImg from '../../../assets/media/blockchain.jpg'
import MobDevImg from '../../../assets/media/mobDev.jpg'
import WebDevImg from '../../../assets/media/webDev.jpg'
import DesignImg from '../../../assets/media/design.jpg'
import AIMIImg from '../../../assets/media/ai-ml.jpg'
import IoTImg from '../../../assets/media/iot-bg.jpg'
import SalesforceImg from '../../../assets/media/sale-force.jpg'
import TestingImg from '../../../assets/media/testing.jpg'
const LaunchPlatform = () => {
    return (
        <>
            <section className='LaunchPlatform-wrap'>
                <Container >
                    <div className='LaunchPlatform-details'>
                        <div className='LaunchPlatform-heading'>
                            <h4 className='h4_title'>Professional Software Development Company</h4>
                            <h2 className='h2_title'>You Choose, We Deliver</h2>
                        </div>
                        <Row>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${BlockchainImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Blockchain</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${BlockchainImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Blockchain</h4>
                                                <p>We have expertise and specialized blockchain service providers that offer expertise in developing custom blockchain solutions, including smart contract development, decentralized application (DApp) development, and blockchain consulting.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${MobDevImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Mobile Development</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${MobDevImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Mobile Development</h4>
                                                <p>Mobile development encompasses both the development of native apps, which are built for specific operating systems like iOS or Android, and the development of cross-platform apps.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${WebDevImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Web Development</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${WebDevImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Web Development</h4>
                                                <p>Web development is the process of creating and maintaining websites and web applications for the internet. It involves a range of tasks, from designing the visual layout and user interface.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${DesignImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Design</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${DesignImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Design</h4>
                                                <p>Design is a multifaceted and creative discipline that involves graphic design, industrial design, architecture, and user experience (UX) design</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${AIMIImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Artificial Intelligence</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${AIMIImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Artificial Intelligence</h4>
                                                <p>Artificial Intelligence and Machine Learning  are transformative fields of computer science that focus on developing systems and algorithms capable of enabling computers to learn from data, recognize patterns, and make intelligent decisions, often without explicit programming.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${IoTImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>IoT</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${IoTImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>IoT</h4>
                                                <p>The Internet of Things (IoT) is a revolutionary concept that involves connecting everyday objects, devices, and systems to the internet, allowing them to collect and exchange data, communicate with each other, and be remotely controlled and monitored.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${SalesforceImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Salesforce Solutions</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${SalesforceImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Salesforce Solutions</h4>
                                                <p>Salesforce enable organizations to manage and streamline various aspects of their business operations, including sales, marketing, customer support, and analytics, all from a single platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.4), rgba(0, 5, 69, 0.6862745098)), url(${TestingImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Software Testing</h4>
                                            </div>
                                        </div>
                                        <div className="flip-card-back"
                                            // style={{
                                            //     background: `linear-gradient(198deg, rgba(0, 0, 0, 0.618), rgba(0, 5, 69, 0.6862745098)), url(${TestingImg})`,
                                            // }}
                                        >
                                            <div className='tile_div'>
                                                <h4 className='h4_title'>Software Testing</h4>
                                                <p>Software testing is a critical phase in the software development lifecycle that involves systematically evaluating a software application or system to identify defects, errors, and inconsistencies.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default LaunchPlatform