import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, Container, Image, Row } from 'react-bootstrap'
import IPLForm from './Form'
import Property from '../../assets/media/property.jpg'
export default function PropertyForecasterIndex() {
    return (
        <>
            <Header />
            <section className='agriculture_prediction_page'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <Image src={Property} alt='agriculture-ai' fluid/>
                        </Col>
                        <Col lg={6}>
                            <h1>Bengaluru Property Worth Forecaster</h1>
                            <IPLForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
