import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap'
export default function ContentDiv() {
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Enhance User Experience and Increase Engagement with AngularJS Web Development Services</h2>
                                    <p>With the growing demand for single-page applications, Angular has established itself as a standard in the creation of web-based applications. Angular is extensively used for constructing dynamic and interactive front-ends of online and mobile applications. It was developed by Google and is maintained by a big community of tech enthusiasts and organizations. This TypeScript-based framework adheres to the most recent JavaScript standards and speeds up the creation of online applications.</p>
                                    <p>Infograins, a dependable AngularJS development firm, assists enterprises in meeting their business and IT requirements by providing customer-oriented, intuitive, and lightweight web app solutions. We satisfy the specific needs of organizations of all sizes and across industries through our AngularJS web development services.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/nft_section_2/Home-page-Services_snuiof'} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
