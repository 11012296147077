import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AutomaticNumberForm from './Form'
import Car from '../../assets/media/car.jpg'
export default function VehicleNumberPlateIndex() {
    return (
        <>
            <Header />
            <section className='leaf_prediction_page'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <Image src={Car} alt='Car' fluid/>
                        </Col>
                        <Col lg={6}>
                            <h1>Automatic Number Plate Recognition</h1>
                            <AutomaticNumberForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
