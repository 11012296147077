import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Achievements from './Achievements';
import HeroSection from './HeroSection';
import Card from './Card';
import Owl from './Owl';
import Services from './Services';
import Technologies from './Technologies';
import { Helmet } from 'react-helmet';

const ObjectRecognition = (props) => {
    const { slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Object Recognition – Infograins</title>
                <meta name="description" content="The greatest Object Recognition is provided by Infograins professionals to identify insignificant to major facial procedures in image, video, and other formats." />
            </Helmet>
            <HeroSection />
            <Achievements />
            <Services />
            <Technologies />
            {/* <Owl /> */}
            <Card />
        </>
    )
}

export default ObjectRecognition