import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Friendly User Interface',
        content: 'Power BI is made to be intuitive and simple to use so that users can get reports and dashboards right away. It is simple to use drag-and-drop functionalities if users want to add adjustments afterwards. Businesses favor on-the-go, mobile-friendly models when it comes to business or CRM apps. Additionally compatible in this regard is Power BI. '
    },
    {
        title: 'Integration of Microsoft Products',
        content: 'Power BI can be easily linked with frequently used Microsoft products like Excel, Teams, SharePoint, Azure, and Dynamics 365, as any Power BI development business can tell you. Users benefit from this by finding it simple to analyze, share, and access data across many platforms.'
    },
    {
        title: 'Data Visualization',
        content: 'Large amounts of data can be challenging to process, but visuals make it easier to process smaller amounts of data. There are various types of data visualizations, including pie charts, stacked bar charts, column charts, and tree maps. There are also several visualization maps available. Power BI is adaptable and makes it simple to customize the available graphs and charts.'
    },
    {
        title: 'Cloud-based',
        content: 'Power BI prides itself on being mobile, which is why it offers a wide range of cloud-based solutions. Power BI\'s service, embedded, premium, mobile, and data flows are among them. While embedding enables the integration of dashboards and reports in your websites or applications, service offers a cloud-based option for sharing, collaborating, and publishing.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Power-Packed Features Of Power BI</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements