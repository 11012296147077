import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Are You Ready to Begin Web App Development?</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Infograins is noted for its affordable pricing, industry-specific web app creation, integration of cutting-edge web development technology, and scalability. To</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
