import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
const Owl = () => {

    return (
        <>
            <div className='w3-OwlCarousel-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='image_div'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_four/web_paragraph-3_zj1uhh'} fluid />
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='hero_slide_section_content'>
                                <div className='hero_content_div'>
                                    {/* <h3 className='h3_title hero_cont_subheading'>{BlockchainCate.subheading}</h3> */}
                                    <h2 className='h2_title hero_cont_heading'>Conversational AI: easier said than done</h2>
                                    <div className='hero_cont_para'>The number of parameters in conversational AI models has risen to millions or billions over time. The model is more accurate the more data there are. This size of model demands the most advanced deep learning (DL) and machine learning (ML) frameworks, which might take weeks to train. You must prepare to switch from a tactical to a strategic use of natural language if you want to accomplish it properly.
                                    </div>
                                    {/* <button className='hero_cont_btn'>Click Me</button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Owl