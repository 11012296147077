import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Get Automated Business Processes that Are Consistent And Slick. ",
            content: "We automate numerous procedures connected to sales and customer support, like giving discounts, following up with prospects, and prioritizing situations. We can automate business processes of any complexity using code-based customizations or the standard Salesforce process automation features, depending on your company's needs. You'll be able to better manage various sales regions, goods, and services, as well as raise client satisfaction, as a result."
        },
        {
            title: "Obtain A Salesforce Solution That is User-Friendly.",
            content: "For sales managers, salespeople, and customer service managers and agents, working with a Salesforce solution is simple and beneficial because we know how to create a smooth user experience."
        },
        {
            title: "Profit from the Solution Delivery Process's Structure.",
            content: "We deliver solutions in stages using a phased strategy, ensuring that the costs of the project are kept in check and the solution meets your needs. Therefore, for large implementation projects, we can first supply basic functionality and then subsequently add extra features. In this scenario, clients can utilize the solution right away and enjoy full satisfaction of their initial needs. If one-time delivery better suits your company's needs, we can also do that."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Benefits of Infograins' Salesforce Implementation</h2>
                                <p>Businesses from a variety of industries can: </p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
