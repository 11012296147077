import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const NFTCate = [
    {
        title:'Corporate Website Design',
        content:'Our aim is to improve your brand by establishing a reputation. Your website serves as a potential customer\'s first point of contact. We provide an exquisite and informative website that is tailored to your needs and operations. Our website design services are tailored to the goals that your brand stands for.'
    },
    {
        title:'E-commerce Websites',
        content:'We recognize the value of admiral website for your company. By providing you with aesthetically pleasing web design services, we will improve your online reputation. For ease of usage, we\'ll offer simple call-to-action buttons and a number of categories relevant to your company.'
    },
    {
        title:'Customized Website',
        content:'Without a little bit of customization, your website is just another online page. As a skilled website design firm, we guarantee to develop a website that will inspire confidence in your audience regarding your ability to provide service.'
    },
    {
        title:'Landing Pages Design',
        content:'A landing page would be the first thing your customer would see. It should astound the viewer sufficiently to keep them on the website. We\'ll set you up with a landing page that highlights the objectives and successes of your company.'
    },
    {
        title:'Responsive Web Design',
        content:'No longer is there a place for dull web pages! It\'s time to get into responsive web design if you want to give your customers a flexible, fluid experience across all devices. With the help of our excellent web design services, we will make this feasible.'
    },
    {
        title:'Wireframing',
        content:'We provide services for improved wireframing as a well-known website design firm. On various webpages, our knowledgeable and skilled professional will demonstrate the fundamental structure and components of your website. You\'ll get a sense of how your website will seem to visitors thanks to this.'
    },
]
export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                <h2 className='h2_title'>We Deliver Comprehensive Website Design Services</h2>
                                <p>Outstanding Website Designs for Businesses, Entrepreneurs, and Startups</p>
                            </div>
                        </Col>
                        {NFTCate.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
