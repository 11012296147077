import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                <h2 className='h2_title'>IOS App</h2>
                <h3 className='h3_title'>Top IOS App Development Company</h3>
                <p>Infograins is the best-fit IOS App Development Company for you. Employ our full-cycle iOS app development services to create and publish your next-generation iOS applications that have been rigorously tested for excellent performance.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection