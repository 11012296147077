import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import joyStick from "../../../assets/images/background/games/joystick.png"

const GameSolution = () => {
    return (
        <>
            <section className='GameSolution-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}><div className='GameSolution-text'>
                            {/* <h3 className='h3_title'>subheading</h3> */}
                            {/* <h2 className='h2_title'>title</h2> */}
                            <p>IoT Dashboards are a visually appealing approach to analyze data. IoT integration with dashboard embedded analytics. Using graphs, tables, charts, and maps to visualize data and information is a great use of IoT Dashboard and Analytics. The data generated by connected devices to the IoT cloud can be used by businesses to derive actionable insights. IoT devices provide the data, which is then displayed on a computer or mobile device. Users can access processes and manage and monitor them with the use of machine learning, artificial intelligence, and the internet of things. By visualizing data devices, dashboards make it possible to govern every aspect of connected devices and perspectives.</p>
                            <p>Dashboards process device data for subsequent processing and are easily customized without causing any disturbances. IoT Dashboard and Analytics have simplified every difficulty associated with connected devices. IoT dashboards are the main usage of IoT platforms, and user interference makes it easier for users to engage with other connected devices. IoT insights are advantages of IoT analytics that aid in obtaining analytics solutions. The launch, after-launch, and evolution ways need to be put up when using IoT Analytics solutions.</p>
                            <div className="center">
                                <a href="#"><span data-attr="Click">Click</span><span data-attr="Me">Me</span></a>
                            </div>
                        </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='GameSolution-img'>
                                <Image src={joyStick} alt='GameSolution' fluid />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameSolution