import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Readability',
        content: 'Python\'s concise and clean syntax makes it simple to comprehend and maintain, cutting development time and expenses.'
    },
    {
        title:'Versatility',
        content: 'It has a wide range of applications, including web development and data analysis, as well as artificial intelligence and scientific computing.'
    },
    {
        title:'Large community',
        content: 'Python has a large and active community, which ensures ongoing support, upgrades, and a plethora of third-party libraries.'
    },
    {
        title:'Scalability',
        content: 'Python can grow with your company. It is appropriate for small projects as well as large-scale, enterprise-level applications.'
    },
    // {
    //     title:'WordPress and Drupal',
    //     content: 'We have experience creating websites with WordPress and Drupal.'
    // },
    // {
    //     title:'PHP Saas development',
    //     content: 'Our developers can assist you in creating scalable SaaS applications.'
    // }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>What makes Python unique?</h2>
                                <p>Python has grown in popularity for a variety of reasons, including:</p>
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
