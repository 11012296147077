import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import gameGirl from "../../../assets/images/trusted/aceRemove.png"

const OurGame = () => {
    const GameCate = [
        {
            title: " Modernized equipment",
            content: ""
        },
        {
            title: "Compile pertinent information and data",
            content: ""
        },
        {
            title: "Display info on a device",
            content: ""
        },
    ]
    return (
        <>
            <section className='ourGame-wrap'>
                <Container>
                    <div className='ourGame-title'>
                        {/* <h3 className='h3_title'>subheading</h3> */}
                        <h2 className='h2_title text-center'>IoT dashboard and analytics factors</h2>
                    </div>
                    <Row>
                        {
                            GameCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='ourGame-card-wrap'>
                                        <figure className="ourGame-card-img">
                                            <Image src={gameGirl} alt='Ace' />
                                        </figure>
                                        <div className='ourGame-card-detail'>
                                            <h3 className='h3_title'>{e.title}</h3>
                                            <p>{e.content}</p>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurGame