import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>World Class Blockchain Development Services</h2>
                <div className='description'>Our team of skilled Blockchain developers specializes in building personalized blockchain solutions that cater to the particular requirements of your company.Infograins provides Blockchain Development services, from ideation and design to implementation and maintenance. This comprehensive approach ensures that enterprises can seamlessly integrate blockchain technology into their existing systems.
  In addition to secure and transparent transactions, effective data and digital asset management, virtual property ownership, and other features, we design and develop strong decentralized apps (dApps) and smart contracts.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services