import React, { useEffect } from 'react'
import Banner from './Banner'
import BusinessModel from './BusinessModel'
import DevelopmentModel from './DevelopmentModel'
import { useLocation } from 'react-router-dom'

export default function MainEngagement() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <>
      <Banner />
      <BusinessModel />
      <DevelopmentModel />
    </>
  )
}
