import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "360-Degree Services for Salesforce",
            content: "A track record of providing full-range Salesforce services, including consulting, development, implementation, integration, and support."
        },
        {
            title: "Excellence Center",
            content: "Dedicated Salesforce CoE is a group of qualified experts who have deep subject-matter knowledge."
        },
        {
            title: "Gold Partner for Salesforce",
            content: "Using a worldwide delivery strategy, Salesforce consulting partner with gold capabilities offers a wide range of services."
        },
        {
            title: "Industry knowledge",
            content: "Salesforce services have extensive expertise in accelerating the digital transformation across a variety of industries, including insurance, fintech, retail, and more."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Infograins?</h2>
                                {/* <p>Infograins exemplifies creativity, consumer focus, and technology innovation through well-defined design procedures. We produce responsive and adaptive designs with consistent cross-platform compatibility using a unique blend of modern technologies and new ideas. We are known in the design industry for our creative zeal and hands-on knowledge in all elements of UI/UX, and we have over two decades of experience. We understand what it takes to establish a brand from a design standpoint and how to do so with modern technologies and methods of thinking. We assist customers in thriving by simple designs that can leave a lasting impression on the minds of users, increasing brand awareness and loyalty.</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
