import React from 'react'
import { Col, Container, Row, Tabs, Tab, Image } from 'react-bootstrap'
import AndroidSvg from '../../../assets/media/mobile/android.svg'
import AppceleratorTitaniumSvg from '../../../assets/media/mobile/appcelerator-titanium.svg'
import IOSSvg from '../../../assets/media/mobile/apple-icon.svg'
import AugmentRealitySvg from '../../../assets/media/mobile/augment-reality.svg'
import BeaconSvg from '../../../assets/media/mobile/beacon.svg'
import FlutterSvg from '../../../assets/media/mobile/flutter.svg'
import IonicSvg from '../../../assets/media/mobile/ionic.svg'
import IpadSvg from '../../../assets/media/mobile/ipad.svg'
import KotlinSvg from '../../../assets/media/mobile/kotlin.svg'
import PhonegapSvg from '../../../assets/media/mobile/phonegap.svg'
import ReactNativeSvg from '../../../assets/media/mobile/react-native.svg'
import SwiftIconSvg from '../../../assets/media/mobile/swift-icon.svg'
import VirtualRealitySvg from '../../../assets/media/mobile/virtual-reality.svg'
import WearableSvg from '../../../assets/media/mobile/wearable.svg'
import WindowsSvg from '../../../assets/media/mobile/windows.svg'
import XamarinSvg from '../../../assets/media/mobile/xamarin.svg'

import AngularSvg from '../../../assets/media/frontend/angular.svg'
import GolangSvg from '../../../assets/media/frontend/golang.svg'
import JavascriptSvg from '../../../assets/media/frontend/javascript.svg'
import ReactJsSvg from '../../../assets/media/frontend/react-js.svg'
import SenchatouchSvg from '../../../assets/media/frontend/senchatouch.svg'
import TypescriptSvg from '../../../assets/media/frontend/typescript.svg'

import CakephpSvg from '../../../assets/media/backend/cakephp.svg'
import CodeigniterSvg from '../../../assets/media/backend/codeigniter.svg'
import CsharpSvg from '../../../assets/media/backend/csharp.svg'
import DjangoSvg from '../../../assets/media/backend/django.svg'
import GraphqlapiSvg from '../../../assets/media/backend/graphqlapi.svg'
import JavaSvg from '../../../assets/media/backend/java.svg'
import LaravelSvg from '../../../assets/media/backend/laravel.svg'
import NodejsSvg from '../../../assets/media/backend/nodejs.svg'
import ParseSvg from '../../../assets/media/backend/parse.svg'
import PhpSvg from '../../../assets/media/backend/php.svg'
import PythonSvg from '../../../assets/media/backend/python.svg'
import RubyOnRailsSvg from '../../../assets/media/backend/ruby-on-rails.svg'
import ScalaSvg from '../../../assets/media/backend/scala.svg'
import SymfonySvg from '../../../assets/media/backend/symfony.svg'
import WindowsDotnetSvg from '../../../assets/media/backend/windows-dotnet.svg'
import YiiSvg from '../../../assets/media/backend/yii.svg'
import ZendSvg from '../../../assets/media/backend/zend.svg'

import BigcommerceSvg from '../../../assets/media/cms/bigcommerce.svg'
import CsCartSvg from '../../../assets/media/cms/cs-cart.svg'
import DrupalSvg from '../../../assets/media/cms/drupal.svg'
import JoomlaSvg from '../../../assets/media/cms/joomla.svg'
import MagentoSvg from '../../../assets/media/cms/magento.svg'
import PrestashopSvg from '../../../assets/media/cms/prestashop.svg'
import SitecoreSvg from '../../../assets/media/cms/sitecore.svg'
import UbercartSvg from '../../../assets/media/cms/ubercart.svg'
import VirtuemartSvg from '../../../assets/media/cms/virtuemart.svg'
import WordpressSvg from '../../../assets/media/cms/wordpress-icon.svg'

export default function Technology() {
    return (
        <>
            <section className='technology_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                <h2>Technologies We Work On</h2>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='technology_content'>
                                <Tabs
                                    defaultActiveKey="mobile"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="mobile" title="Mobile">
                                        <Row className='justify-content-center'>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={AndroidSvg} fluid />
                                                    <h6>Android</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={AppceleratorTitaniumSvg} fluid />
                                                    <h6>Appcelerator Titanium</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={IOSSvg} fluid />
                                                    <h6>IOS</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={AugmentRealitySvg} fluid />
                                                    <h6>Augment Reality</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={BeaconSvg} fluid />
                                                    <h6>Beacon</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={FlutterSvg} fluid />
                                                    <h6>Flutter</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={IonicSvg} fluid />
                                                    <h6>Ionic</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={IpadSvg} fluid />
                                                    <h6>Ipad</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={KotlinSvg} fluid />
                                                    <h6>Kotlin</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={PhonegapSvg} fluid />
                                                    <h6>PhoneGap</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={ReactNativeSvg} fluid />
                                                    <h6>React Native</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={SwiftIconSvg} fluid />
                                                    <h6>Swift</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={VirtualRealitySvg} fluid />
                                                    <h6>Virtual Reality</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={WearableSvg} fluid />
                                                    <h6>Wearable</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={WindowsSvg} fluid />
                                                    <h6>Windows</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={XamarinSvg} fluid />
                                                    <h6>Xamarin</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="frontend" title="Frontend">
                                        <Row className='justify-content-center'>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={AngularSvg} fluid />
                                                    <h6>Angular</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={GolangSvg} fluid />
                                                    <h6>Golang</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={JavascriptSvg} fluid />
                                                    <h6>Javascript</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={ReactJsSvg} fluid />
                                                    <h6>ReactJs</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={SenchatouchSvg} fluid />
                                                    <h6>Senchatouch</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={TypescriptSvg} fluid />
                                                    <h6>Typescript</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="backend" title="Backend">
                                        <Row className='justify-content-center'>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={CakephpSvg} fluid />
                                                    <h6>Cakephp</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={CodeigniterSvg} fluid />
                                                    <h6>Codeigniter</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={CsharpSvg} fluid />
                                                    <h6>Csharp</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={DjangoSvg} fluid />
                                                    <h6>Django</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={GraphqlapiSvg} fluid />
                                                    <h6>Graphql API</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={JavaSvg} fluid />
                                                    <h6>Java</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={LaravelSvg} fluid />
                                                    <h6>Laravel</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={NodejsSvg} fluid />
                                                    <h6>Nodejs</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={ParseSvg} fluid />
                                                    <h6>Parse</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={PhpSvg} fluid />
                                                    <h6>Php</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={PythonSvg} fluid />
                                                    <h6>Python</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={RubyOnRailsSvg} fluid />
                                                    <h6>Ruby On Rails</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={ScalaSvg} fluid />
                                                    <h6>Scala</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={SymfonySvg} fluid />
                                                    <h6>Symfony</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={WindowsDotnetSvg} fluid />
                                                    <h6>Windows Dotnet</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={YiiSvg} fluid />
                                                    <h6>Yii</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={ZendSvg} fluid />
                                                    <h6>Zend</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="cms" title="CMS">
                                        <Row className='justify-content-center'>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={BigcommerceSvg} fluid />
                                                    <h6>Bigcommerce</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={CsCartSvg} fluid />
                                                    <h6>CS Cart</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={DrupalSvg} fluid />
                                                    <h6>Drupal</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={JoomlaSvg} fluid />
                                                    <h6>Joomla</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={MagentoSvg} fluid />
                                                    <h6>Magento</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={PrestashopSvg} fluid />
                                                    <h6>Prestashop</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={SitecoreSvg} fluid />
                                                    <h6>Sitecore</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={UbercartSvg} fluid />
                                                    <h6>Sitecore</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={VirtuemartSvg} fluid />
                                                    <h6>Virtuemart</h6>
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={6} xs={6}>
                                                <div className='tech_div'>
                                                    <Image src={WordpressSvg} fluid />
                                                    <h6>Wordpress</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
