import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Product Specification",
            content: "Before developing a product, it is critical to consider the overall context and lay the groundwork for the product. All stakeholders involved collaborate to develop an outline that includes team organization, communication strategies, and user expectations."
        },
        {
            title: "Research",
            content: "Once the product description is complete, designers conduct thorough research from both the user and market perspectives. Experts do extensive research based on qualitative data obtained through various channels in comparison to their complexity, timing, resources, and other factors."
        },
        {
            title: "Analysis",
            content: "It is now time to examine the insightful data gathered from data sources throughout the research phase. Designers are involved in the design of the final product, the establishment of user groups, and the business workflow."
        },
        {
            title: "Design",
            content: "Designers are tasked with creating several components ranging from information architecture to the actual user interface and user experience design. Sketching, wireframes, and prototypes are created with equal collaboration from all concerned stakeholders."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our UI UX Design Process</h2>
                                <p>Infograins exemplifies creativity, consumer focus, and technology innovation through well-defined design procedures. We produce responsive and adaptive designs with consistent cross-platform compatibility using a unique blend of modern technologies and new ideas. We are known in the design industry for our creative zeal and hands-on knowledge in all elements of UI/UX, and we have over two decades of experience. We understand what it takes to establish a brand from a design standpoint and how to do so with modern technologies and methods of thinking. We assist customers in thriving by simple designs that can leave a lasting impression on the minds of users, increasing brand awareness and loyalty.</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
