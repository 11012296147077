import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Testimonial = () => {
    const options = {
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    const testimonialData = [
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/clipboard-image_jf6dlk',
            name: "Raymond N.",
            detail: "The freelancer was great, he listened to all our problems and help us find a solution that works best for everyone. I would work with him again!"
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/images_syrsq3',
            name: "John Cook",
            detail: "We are getting a good response from our clients & soon we will launch the product on the market too. The team was awesome & knew their responsibilities & provided me a satisfactory delivery."
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/istockphoto-1297832726-170667a_xtppmz',
            name: "Andreas Groose",
            detail: "They go above and beyond, and project managers check in with customers and offer support after they leave work. The team is professional and skilled."
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/olivier-feat_-_Copy_zya0gj',
            name: "Nuke Goldstein",
            detail: "Thanks to the Infograins team, the company successfully launched a platform with blockchain connectivity. They followed a hybrid working process, which worked effectively for both teams. The client also lauded their responsiveness. Overall, the collaboration brought a positive working experience."
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/clipboard-image_2_uebtb4',
            name: "Thiban",
            detail: "Absolutely the greatest Team on Freelancer!! After trying out different freelancers for the last 3 years I regret not having found point2solutions earlier. I am almost afraid to type all this because I want to keep this team for myself. This is now my go-to team for all my projects!"
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/clipboard-image_1_dtka3w',
            name: "Philip",
            detail: "Arti and her team at Infograins are amazing to work with and provide excellent work. I have been working with them for many months now and they have been great since day one, I plan on working with them more in the future as well as recommending other people to them."
        },
        {
            image: 'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/testimonials/1582714282756876092_u5xwf6',
            name: "Darlina",
            detail: "Infograins is one of the leading offshore software development companies routed from Portland, Oregon USA and has its delivery centre in Indore, India."
        },

    ]
    return (
        <>
            <section className='testimonial_wrap'>
                <Container>
                    <div className='testimonial_inner_wrap'>
                        <Row>
                            <Col sm={4} md={4} lg={4} xl={4}>
                                <div className='testimonial_title_wrap'>
                                    <h3>Testimonial</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="521" height="14" viewBox="0 0 521 14" fill="none">
                                        <path d="M0.5 12.5C90 -2.49999 484 -1.89997 520 12.5" stroke="#F48533" strokeWidth="2.39456" />
                                    </svg>
                                    <p>View the opinions of our client regarding us <br /> and our work.</p>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={6} xl={6}>
                                <OwlCarousel
                                    className="owl-theme testimonial_client"
                                    loop
                                    {...options}>
                                    {
                                        testimonialData.map((event, index) => {
                                            const { image, name, detail } = event
                                            return (
                                                <div className="item" key={index}>
                                                    <figure className='client_img'>
                                                        <Image src={image} alt="client" fluid />
                                                    </figure>
                                                    <article className='client_article'>
                                                        <h4>{name}</h4>
                                                        <p>{detail}</p>
                                                    </article>
                                                </div>
                                            )
                                        })
                                    }

                                </OwlCarousel>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Testimonial