import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import joyStick from "../../../assets/images/background/games/joystick.png"

const GameSolution = () => {
    return (
        <>
            <section className='GameSolution-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='GameSolution-text'>
                                {/* <h3 className='h3_title'>{GameCate.subheading}</h3> */}
                                <h2 className='h2_title'>We Are Expertise in Such Areas</h2>
                                <p>Our game development company places equal professional emphasis on quick and effective game porting as well as full-cycle game development from start and co-development of gaming solutions with highly effective teamwork. Give your idea to a group of game developers who have the potential to become real stars in the gaming world.</p>
                                <div className="center">
                                    <a href="#"><span data-attr="Click">Click</span><span data-attr="Me">Me</span></a>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='GameSolution-img'>
                                <Image src={joyStick} alt='GameSolution' fluid />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GameSolution