import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Planning",
            content: "With the correct technology, a front-end development strategy provides excellent client-side rendering of websites or web apps. A good front-end rendering improves the user experience and makes websites and applications appear and feel better."
        },
        {
            title: "Designing",
            content: "We improve the UI quality of websites and web applications using industry standard frontend design concepts. We prioritize design because it is the first thing your users will notice."
        },
        {
            title: "Development",
            content: "Bootstrap, Google Fonts, Less, jQuery, Git, and Atom are among the most popular front-end development tools. Aside from being unique, the open-source platform facilitates the acquisition of substantial developer assistance and ideas."
        },
        {
            title: "Testing",
            content: "Front-end testing automation allows for improved debugging and source code modification on an open-source platform. To deliver a perfect product to market, we do a full testing process, from analysis through maintenance."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3>
                                <h2 className='h2_title'>{head.heading}</h2>
                                <p>{head.description}</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
