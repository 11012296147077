import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Blockchain Testing Services</h2>
                                <p>A Single Factor That Determines Blockchain Applications' Success</p>
                                <p>With the goal of bringing a revolutionary shift in the quality of blockchain-based applications to make them more secure and swifter, Infograins offers tech proficient blockchain testing services. Our End-to-end testing services that are specifically focused on improving the performance and security of Blockchain-based apps.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
