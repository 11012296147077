import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoxSections from './BoxSections'
import ContentDiv from './ContentDiv'
import HeroSections from './HeroSections'
import KeyFeature from './KeyFeature'
import { Helmet } from 'react-helmet'

export default function Main_nft(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Salesforce Implementation Services – Infograins</title>
                <meta name="description" content="With over a decade of experience in IT industry, Infograins is fully capable to provide you high-quality Salesforce implementation services to meet your business needs." />
            </Helmet>
            <HeroSections />
            <BoxSections />
            <ContentDiv />
            <KeyFeature />
        </>
    )
}
