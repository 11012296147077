import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import finance from '../../../assets/images/hire/icons/finance.png'
import health from '../../../assets/images/hire/icons/health.png'
import insurance from '../../../assets/images/hire/icons/insurance.png'
import transport from '../../../assets/images/hire/icons/transport.png'
import realState from '../../../assets/images/hire/icons/realState.png'
import gasoil from '../../../assets/images/hire/icons/gasoil.png'
import chain from '../../../assets/images/hire/icons/chain.png'

export default function Process() {
    return (
        <>
            <section className='process_section'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className="head">
                                <h2>The Benefits of Outsourcing IT Staffing Services</h2>
                                <p>Services for IT staffing can be outsourced and have several advantages. The opportunities are infinite if you have the appropriate IT staffing firm on your side.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={finance} fluid />
                                <h4>Availability of top IT talent</h4>
                                <p>The most competitive resource in the software industry is talent. The best IT staffing companies will locate the area's top IT specialists and effortlessly integrate their skills into your project.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={transport} fluid style={{width:"70px"}} />
                                <h4>Enhanced Workloads​</h4>
                                <p>Working with IT staffing firms relieves you of responsibility. To kick-start any project and maximize operational procedures, get instant access to the top people.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={health} fluid style={{width:"40px"}} />
                                <h4>Industry knowledge</h4>
                                <p>Your business gains from the senior software development teams' extensive industry knowledge when you outsource staffing services. This degree of expertise cannot be replaced.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={insurance} fluid style={{width:"40px"}} />
                                <h4>Small Risks​</h4>
                                <p>Risk management is significantly simplified when you have the greatest IT specialists on your team. The best practices will be used by a top-notch IT staffing firm to boost the flexibility and dependability of projects.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={chain} fluid />
                                <h4>Faster launch times​</h4>
                                <p>Any IT team can become successful with the aid of staffing solutions. A staffing firm will develop unique solutions for your organization and put together the best teams to complete projects on schedule.</p>
                            </div>
                        </Col>
                        <Col lg={3} >
                            <div className='team_content'>
                                <Image src={realState} fluid style={{width:"42px"}} />
                                <h4>Superior Products​</h4>
                                <p>It is simple to understand how IT staffing services result in high-quality software solutions when the aforementioned advantages are taken into account. Check references and prior efforts, but, as well!</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
