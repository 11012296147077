import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <>
      <section className='w3Service-wrap'>
        <Container>
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <figure className='w3-service-img'>
                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_two/web_paragraph-1_ydotqn'} alt="W3 Service" />
              </figure>
            </Col>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div className='w3-service-about'>
                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                <h2 className='h2_title'>IMPORTANCE OF SERVICES FOR SENTIMENT ANALYSIS </h2>
                <div className='description'>With the aid of sentiment analysis, businesses can quickly locate online discussion of their brands and classify it as good, negative, or neutral. This gives firms the ability to evaluate marketing and public relations efforts more effectively, enhance customer service, and pinpoint appealing aspects of their goods and services to develop further.</div>
                {/* <button className='btn' type='button'>Get Free Consultancy</button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Services