import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Featured from './Featured'
import GameHero from './GameHero'
import GameNews from './GameNews'
import GameSolution from './GameSolution'
import OurGame from './OurGame'
import { Helmet } from 'react-helmet'
const GameDevelopment = (props) => {
  const { game_slug } = useParams()
  // =========================== scroll To Top default =========================
  useEffect(() => {
    props.demo('top')
  }, [game_slug])
  // =========================== scroll To Top default =========================
  return (
    <>
      <Helmet>
        <title>Leading Game Development Company – Infograins</title>
        <meta name="description" content="Infograins is a leading game development company that provides comprehensive suite of services in all levels of game development." />
      </Helmet>
      <GameHero />
      <GameSolution />
      <OurGame />
      <GameNews />
      <Featured />
    </>
  )
}

export default GameDevelopment