import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {
    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Website Testing Services</h2>
                                <p>You want visitors to your website to convert into paying customers, and as a website testing business, we know how to make that happen. Web testing services on every level provide you trust in your solution and help you stand out from the crowd. Make your web app accessible, safe, and performant across all browsers and devices used by your audience.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
