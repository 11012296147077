import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                {/* <h3 className='h3_title'>{BlockchainCate.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Our Service for Unity3D App Development?</h2>
                                <div className='description'>Not only do we have good experience, but also the best in the business. We have established ourselves as the top Unity 3D application development company in India & providing a wide range of services across the globe. We promise that our satisfied services will put an end to your exhaustive search for unity 3D game developers. With a cost-effective app development service, you may benefit from the advantages of the top services.</div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies