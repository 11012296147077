import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top WordPress Development Company</h2>
                                <p>Infograins is your reliable WordPress development partner. WordPress is a sophisticated content management system (CMS) that can transform your digital presence into a dynamic, user-friendly, and highly configurable online platform. Our skilled WordPress developers are available to assist you in realizing the full potential of this versatile platform.
                                </p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
