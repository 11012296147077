import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap"
import empower from "../../../assets/images/hireDeveloperAdd/empower.png"
import empowerIcon from "../../../assets/images/Empower.svg"
const HireAddEmpower = () => {
    return (
        <>
            <section className='hireAddEmpower_wrap'>
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <figure className='hireAddEmpower_img_wrap'>
                                <Image src={empower} alt='Empower' fluid />
                            </figure>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <div className='hireAddEmpower_about'>
                                <div className='empower_icon_img'>
                                <Image src={empowerIcon} alt="empowerIcon" fluid />
                                </div>
                                 <h3 className='h3_title'><span>Empower</span> Your Business With Developers From Infograins</h3>
                                <p> Infograins is one of the leading companies offering to hire developers with great expertise and skillset. Our developers propose solutions that are perfect in every sense. You can hire Blockchain Developers, Solidity Developers, App Developers, iOS Developers, Python Developers, NFT Developers, and many more to fulfill your development requirements.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HireAddEmpower