import React, {useEffect} from 'react'
import NotFound from '../NotFound'

function EventIndex(props) {
     // =========================== scroll To Top default =========================
   useEffect(() => {
    props.demo('top')
  }, [])
  // =========================== scroll To Top default =========================
  return (
    <>
    <NotFound/>
    </>
  )
}

export default EventIndex

