import React from 'react'
import BlogSection from '../../pages/landingPages/BlogSection'

export default function Blog() {
    return (
        <>
            <section className='blog_section_new'>
                <BlogSection/>
            </section>
        </>
    )
}
