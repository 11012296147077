import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Why Choose Inograins for Mobile App Testing?</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Our team of skilled testers has broad experience in mobile app testing across various industries. We leave no stone unturned, testing every aspect of your app to guarantee its success. We use the latest testing tools and approaches to ensure accurate and efficient testing. Your app's success is our priority. We work closely with you to understand your goals and modify our testing services accordingly.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
