import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

function PressHero() {
    const navigate = useNavigate()
    const [pressEventData, setPressEventData] = useState([])
    const getEventData = async () => {
        try {
            const api = await axios.get(`${process.env.REACT_APP_BASE_URL}event/get-upcoming-event/`)
            setPressEventData(api.data.response)
        } catch (error) {
            console.log("upcoming", error)
        }
    }

    useEffect(() => {
        getEventData()

    }, [])

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,

            }
        },
    };

    // console.log("pressEventData", pressEventData);
    
    const handleNavigate = (id) => {
        navigate(`/press-release/${id}`)
    }

   
    return (
        <>
            <Helmet>
                <meta name="description" content="Press Releases keep you informed on latest developments and happenings at Infograins." />
                <title>Press Release|Infograins</title>
            </Helmet>
            <div className='press_main'>

                <div className="press_hero_section">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="press_hero_heading">Press Release</div>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <div className="press_hero_content">
                    <div className='OwlCarousel-wrap'>
                        {pressEventData.length > 0 ? (<OwlCarousel className='owl-theme hero_slider' loop margin={10} {...options}>
                            <Container>

                                {pressEventData.map((e, index) => {
                                    return (
                                        <div className='item' key={index}>
                                            <Row>
                                                <Col xl={5} lg={5} md={6} sm={12}>
                                                    <div className="press_event_item" onClick={() => handleNavigate(e.event_slug)}>
                                                        <div className="event_batch">Up Coming Events</div>
                                                        <div className="event_title">{e.title}</div>
                                                        <div className="event_desc">{e.description}</div>
                                                        <div>
                                                            <div className='event_subDesc' dangerouslySetInnerHTML={{ __html: e.event_content.content.slice(0, 130) }}></div>
                                                        </div>
                                                        <div className="event_date">{e.event_date}</div>
                                                        <div className="event_location"> {e.event_location}</div>
                                                    </div>
                                                </Col>
                                                <Col xl={7} lg={7} md={6} sm={12}>
                                                    <div className="press_right_img">
                                                        <img src={e.image} alt="event_img" className='img-fluid' />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                                }
                            </Container>
                        </OwlCarousel>) : <h4 className=' text-white d-flex justify-content-center pt-5' style={{height:'450px'}} >Loading....</h4>

                        }

                    </div>
                </div>

            </div>
        </>
    )
}

export default PressHero

