import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'User-oriented approach',
        content:'Our team\'s applications are created with the end user in mind.'
    },
    {
        title: 'Transparency',
        content:'We are honest and open with our clients in everything we do, from pricing to discussing project objectives.'
    },
    {
        title: 'Customized solutions',
        content:'Our years of experience enable us to adapt to each unique idea and be adaptable to the needs of each organization.'
    },
    {
        title: 'Innovative technology',
        content:'We exclusively employ tried-and-true technologies, frameworks, and libraries to assure flawless performance, app security, and client safety.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Infograins for Next Js Web Development</h2>
                                <p>Our team collaborates with you to enable faster-to-market solutions by utilizing the power of Next.Js.</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
