import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title:'Our Offerings',
        content: 'With AngularJS Development Services, you can make your web applications stand out.'
    },
    {
        title:'Custom AngularJS Development:',
        content: 'End-to-end development of AngularJS mobile and web apps that are resilient, scalable, dynamic, and intuitive in accordance with client project criteria.'
    },
    {
        title:'AngularJS API Development:',
        content: 'Custom API creation adapted to the needs of the business to improve the performance and speed of single-page apps, mobile apps, and online apps.'
    },
    {
        title:'AngularJS Maintenance and Support',
        content: 'Maintenance and support for web programs after deployment, including regular quality tests to resolve bugs and smooth out development concerns.'
    },
    {
        title:'Third-party Integrations',
        content: 'Third-party integrations that are seamless to enhance the functionality of Angular-based web apps.'
    },
    {
        title:'AngularJS Migration and Upgradation',
        content: 'Web applications can be easily migrated from other front-end frameworks to Angular, or they can be easily upgraded from AngularJS to the latest Angular version.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        {/* <Col lg={12}>
                            <div className='head_section'>
                                <h3 className='h3_title'>{head.subheading}</h3>
                                <h2 className='h2_title'>{head.heading}</h2>
                                <p>{head.description}</p>
                            </div>
                        </Col> */}
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
