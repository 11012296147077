import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
export default function BoxSections() {
    const NFTCate = [
        {
            title: "Analyze Your Requirements ",
            content: "Thus, we begin by carefully comprehending the underlying issues and your objectives. We are able to explore fresh concepts and provide a great insight regarding any adjustments thanks to a detailed discussion with the QA and testing team."
        },
        {
            title: "Making a Strong Strategy",
            content: "The next step is to develop a methodical and reliable plan for blockchain testing. We do customized testing for this assignment in order to meet your desired objectives."
        },
        {
            title: "Establish the test cases",
            content: "Applying certain metrics to blockchain test cases will allow you to gauge their overall performance. We choose the KPIs to assess significant problems like the scalability and caliber of your blockchain program."
        },
        {
            title: "Test Execution",
            content: "Various methodologies, including UI testing, API testing, security testing, etc., are used in the test execution procedure. To find flaws and problems, we therefore take great care to finish the test."
        },
        {
            title: "Reporting",
            content: "We offer a comprehensive report of the Blockchain Testing once we have completed all the activities, including test execution and analysis. This paper includes details on security testing, project descriptions, smart contracts, and much more."
        }
    ]
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Infograins BlockChain Testing Approach</h2>
                                <p>We follow to precise test techniques and approaches to deal with blockchain application issues permanently. Our method is as follows</p>
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='box_shell'>
                                        <BsBox />
                                        <h5 className='h5_title'>{e.title}</h5>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
