import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'

const BoxData = [
    {
        title: 'WordPress Customization Services',
        content: 'As a WordPress website development pioneer, we provide a wide range of WordPress website development services to startups, SMEs, and major organizations. Our bespoke WordPress solutions are designed to help your business expand faster.'
    },
    {
        title: 'Custom WordPress Web Development',
        content: 'With our Custom WordPress Website Development services, you can establish a strong digital presence and increase your company\'s revenues. We have extensive experience designing feature-rich, scalable, and secure WordPress development solutions that are suited to the specific needs of our different clients across industry verticals.'
    },
    {
        title: 'WordPress Custom Theme Development',
        content: 'Our professionals translate your pixel-perfect designs into a sleek, responsive, and W3C compatible WordPress CMS theme while keeping a mobile-first strategy in mind. We create eye-catching and fast-loading themes for WordPress custom theme development that will make your website stand out.'
    },
    {
        title: 'WordPress CMS Development',
        content: 'Expertise in creating a user-friendly WordPress CMS with scalable capabilities, comprehensive functionality, and robust integrations. When you choose WordPress CMS development services, our professionals will assist you in gaining complete control over your website, allowing you to easily manage your website content. We always make certain that our clients get the most out of the WordPress CMS platform.'
    },
    {
        title: 'WordPress Plugin Creation',
        content: 'Plugins, extensions, and modules can be used to extend the default functionality of your WordPress-powered website. Use our extensive library of custom plugins to meet your diverse business requirements. We assist businesses in realizing the full potential of their WordPress website through custom WordPress plugin development.'
    },
    {
        title: 'WordPress eCommerce Development',
        content: 'Take your online adventure to the next level with a powerful money-making eCommerce website built with WordPress. Our skilled developers have extensive hands-on experience in creating a powerful selling eCommerce store by exploiting the capabilities of this open-source CMS development framework.'
    }
]

export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <div className='head_section'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                {/* <h2 className='h2_title'>Our TypeScript Development Services</h2> */}
                                {/* <p>Python has grown in popularity for a variety of reasons, including:</p> */}
                            </div>
                        </Col>
                        {BoxData.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
