import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
export default function KeyFeature() {
    const NFTCate = [
        {
            title: "Why choose Infograins",
            content: "We work hard to provide professional assistance to you at every step of the way. As a UI/UX design agency, we will become members of your team, mentors, and dependable collaborators, and we will share our design knowledge with you."
        },
        {
            title: "Our Experienced Professionals",
            content: "At Infograins, our dedicated team of professionals is equipped with years of experience in the field of wireframing and prototyping. We understand the importance of creating user-friendly interfaces and functional prototypes that align with your project's goals."
        },
        {
            title: "Full transparency",
            content: "We believe in open and honest communication with our clients throughout the wireframing and prototyping process. You'll receive real-time updates and progress reports, ensuring you're always in the loop."
        },
        {
            title: "High Quality",
            content: "Our team is committed to maintaining the highest standards of quality in every aspect of wireframing and prototyping. We focus on precision and attention to detail to ensure the final product meets your exact specifications."
        }
    ]
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3>
                                <h2 className='h2_title'>{head.heading}</h2>
                                <p>{head.description}</p> */}
                            </div>
                        </Col>
                        {
                            NFTCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                    <div className='key_box'>
                                        <AiFillFire />
                                        <h4 className='h4_title'>{e.title}</h4>
                                        <p>{e.content}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
