import React from 'react';
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import gameGirl from "../../../assets/images/trusted/aceRemove.png"

const OurGame = () => {
    const GameCate = [
        {
            title: "Connect",
            content: "Using internet gateways to broadcast real-time data, Infograins gives common items a voice. RFID tags, beacons, sensors, and networking protocols like NFC, Wi-Fi, and BLE are all used to accomplish this."
        },
        {
            title: "Collect",
            content: "To collect sensor data, guard against data loss, and feed data into storage solutions, we create embedded and distributed systems. Keep your data for future use or send the data instantly to an endpoint or IoT application."
        },
        {
            title: "Act",
            content: "With the help of Infograins, data collected from IoT system components is transformed into actionable insights. The data is categorized, searchable, available through client apps, and visualized to meet endpoint device interface requirements."
        },
    ]
    return (
        <>
            <section className='ourGame-wrap'>
                <Container>
                    <div className='ourGame-title'>
                        {/* <h3 className='h3_title'>subheading</h3> */}
                        <h2 className='h2_title text-center'>IoT dashboard and analytics factors</h2>
                    </div>
                    <Row>
                        {
                            GameCate.map((e, key) => {
                                return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                    <div className='ourGame-card-wrap'>
                                        <figure className="ourGame-card-img">
                                            <Image src={gameGirl} alt='Ace' />
                                        </figure>
                                        <div className='ourGame-card-detail'>
                                            <h3 className='h3_title'>{e.title}</h3>
                                            <p>{e.content}</p>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurGame