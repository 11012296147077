import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BannerForm from '../../common/BannerForm';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";

const GameHero = () => {
    return (
        <>
            <section className='gameHero-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='gameHero-about-wrap'>
                                <h2 className='h2_title'>Game Development Services</h2>
                                <h3 className='h3_title'></h3>
                                <p>Our video game development company manages separate projects, works with the client to co-develop games, and offers extra operational services. Our team's expertise enables us to cover all gaming platforms and create a fantastic product that reflects the client's vision and the users' preferences.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                                <BannerForm />
                        </Col>
                    </Row>
                </Container>
        

            </section>
        </>
    )
}

export default GameHero