import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Flexible Engagement Model',
        content:'We interact with our customers based on their business requirements. Our engagement models are tailored to your organization\'s size and scale.'
    },
    {
        title: 'Experienced Team',
        content:'We created a team of industry-leading specialists with cutting-edge skill sets. Our clients have placed their trust in our team of over 50 professionals.'
    },
    {
        title: 'Agile Methodology',
        content:'Our team manages a project by segmenting it into stages and requiring regular discussion with stakeholders as well as continuous improvement and iteration at each level.'
    },
    {
        title: 'Quality assurance',
        content:'Our quality assurance specialist guarantees that the finished product meets the quality standards of the firm.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Why Choose Infograins for TypeScript Development?</h2>
                                {/* <p>Working with Infograins means a simple and efficient development approach that assures openness at all stages:</p> */}
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
