import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                <h2 className='h2_title'>Android App</h2>
                <h3 className='h3_title'>Top Android App Development Company</h3>
                <p>This is the place where you may find trustworthy Android app development services. You may be sure that the finest solutions for your demands will be provided thanks to our tested approach for producing high-quality mobile apps that work on all Android versions. We make sure your experience is flawless from conception to delivery. So, for personalized solutions, get in touch with the top Android app development company now.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection