import React,{useEffect} from 'react'
import NotFound from '../NotFound'

function HireIndex(props) {
   // =========================== scroll To Top default =========================
   useEffect(() => {
    props.demo('top')
  }, [])
  // =========================== scroll To Top default =========================
  return (
    <div>
        <NotFound/>
    </div>
  )
}

export default HireIndex