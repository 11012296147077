import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Achievements from './Achievements';
import HeroSection from './HeroSection';
import Card from './Card';
import Owl from './Owl';
import Services from './Services';
import Technologies from './Technologies';
import { Helmet } from "react-helmet";

const MobileIndex = (props) => {
    const { slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Android App Development Company – Infograins</title>
                <meta name="description" content="Infograins offer very advanced custom android application development services by our expertise skilled developers. Hire us now." />
            </Helmet>
            <HeroSection />
            <Achievements />
            <Services />
            <Technologies />
            <Owl />
            <Card />
        </>
    )
}

export default MobileIndex