import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Evolution and Continuous Support </h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>In order to adapt to your organizational changes, continuous support services also involve further fine-tuning of Salesforce features and procedures. Additionally, they involve creating any bespoke apps that may be needed to address any post-implementation business needs.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
