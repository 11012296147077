import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const ApiData = [
    {
        title: 'Secure programming',
        para: 'The syntax of Swift removes some potential errors that can occur when creating code in Objective-C.'
    },
    {
        title: 'Easy to Maintain',
        para: 'As opposed to Objective-C, the Swift programming language has fewer dependencies, making it simpler to maintain.'
    },
    {
        title: 'Faster Development',
        para: 'In comparison to Objective-C, Swift is unquestionably one of the fastest programming languages to employ for creating iPhone applications.'
    },
    {
        title: 'Easy Code Readability',
        para: 'Swift code is more expressive, straightforward, tidy, and understandable even to non-technical people.'
    },
    // {
    //     title: 'Post-launch Maintenance',
    //     para: ''
    // },
]
const Card = () => {

    return (
        <>
            <section className='w3Card-wrap'>
                <Container>
                    <div className='w3Card-title'>
                        <h2 className='h2_title'>Our Result Oriented Execution</h2>
                    </div>
                    <div className='w3Card-both'>
                        <div className='w3Card-upper'>
                            <Row>
                                {ApiData.map(({ title, para }, key) => {
                                    return <Col sm={12} md={6} lg={4} xl={4} key={key}>
                                        <div className={`w3-card-body card_number_${key}`}>
                                            <h2 className='h2_title'>{title}</h2>
                                            <p>{para}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Card