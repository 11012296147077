import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Frameworks',
        content: '•	Flutter •	Dart plugins •	Flutter SDK •Dart SDK'
    },
    {
        title: 'Database',
        content: '•	Hive •	ObjectBox•	SQLite•	Firebase realtime database•	Firestore•	Sembast        •	Local Storage•	StorageMoor'
    },
    {
        title: 'Plugins',
        content: '•	Firebase(All service)•	Provider        •	Payment Gateway(Stripe, Braintree, Google Pay, Razorpay)•	Social Sgn InFacebook, Google, Apple)        •	Shared preferences        •	Image picker•	in -App - Purchase•	Map flutter_ffmpeg•	AWS Amplify•	AWS datastore•	Freezed•	Json serializable•	Flutter local notifications•	BLoC'
    },
    {
        title: 'Tools & Utilities',
        content: '•	Android studio•	Visual Studio Code        •	Version control tools (Git, GitLab, Bit Bucket)'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Tools & Technologies We Use For Flutter App</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements