import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Achievements from './Achievements';
import HeroSection from './HeroSection';
import Card from './Card';
import Owl from './Owl';
import Services from './Services';
import Technologies from './Technologies';
import { Helmet } from 'react-helmet';
const RoboticsIndex = (props) => {
    const { slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Infograins Robotics</title>
                <meta name="description" content="Infograins is one of the leading robotics companies across the globe. We're here to create useful robots. Robots that support the growth of individuals & enterprises." />
            </Helmet>
            <HeroSection />
            {/* <Achievements /> */}
            <Services />
            <Technologies />
            {/* <Owl /> */}
            {/* <Card /> */}
        </>
    )
}

export default RoboticsIndex