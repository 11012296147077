import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function PreviousEvents() {
    const [previousEventData, setPreviousEventData] = useState([])
    const navigate = useNavigate()

    const getPreviousEvents = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}event/get-previous-event/`)
            .then((res) => {
                // console.log("previous event response", res.data.response)
                setPreviousEventData(res.data.response)
            })
            .catch((err) => {
                console.log("previous event err", err)
            })
    }

    useEffect(() => {
        getPreviousEvents()
    }, [navigate])

  

    return (
        <>
            <div className="previous_events_section">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className="previou_event_heading">
                                <h2>Our Previous Events</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        {
                            previousEventData.map((e, i) => {
                                return (
                                    <Col xl={4} lg={4} md={6} sm={12} key={i}>
                                        <Link to={`/press-release/${e.event_slug}`}>
                                            <div className="previous_event_item">
                                                <div className="previous_event_img">
                                                    <img src={e.image} alt="previous_event_img" className='img-fluid' />
                                                </div>
                                                <div className="previous_event_content">
                                                    <div className="previous_event_title">{e.title.slice(0,45)}</div>
                                                    <div className="previous_event_desc">{e.description.slice(0,140)}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Container>

            </div>
        </>
    )
}

export default PreviousEvents