import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { BsFillCheckCircleFill } from 'react-icons/bs'
export default function Outcome(props) {
    const data = props.data


    return (
        <>
            <section className='outCome_section pb-5'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head text-center py-5'>
                                <h2>Outcome</h2>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className='outcome_content'>
                                <div className='list_content pt-5'>
                                    <ul>
                                        {
                                            data && data?.project_outcome?.map((e, i) => {
                                                return (
                                                    <li key={i}>
                                                        <div className='list_item' style={{ display: "flex", gap: "20px", marginBottom: "2.5rem" }}>
                                                            <div className='list_icon'><BsFillCheckCircleFill /></div>
                                                            <div className='list_description'>
                                                                {e.content}
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='image_slider'>
                                <div className='overview_img_wrapper'>

                                    {/* {
                                    data && data.project_outcome_image != null ? 
                                    <Image src={data.project_outcome_image.image} className="img_side" alt='overview' fluid />
                                    :
                                    <Image src="https://dummyimage.com/600x400/000/fff" className="img_side" alt='overview' fluid />
                                } */}
                                    <Image src="https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/project_outcome/Asset_10_kimnag" className="img_side" alt='overview' fluid />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
