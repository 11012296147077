import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { BsBox } from 'react-icons/bs'
const NFTCate =[
    { 
        title:'Machine Learning',
        content:'Make applications smarter by creating, implementing, and deploying machine learning models and algorithms that process large amounts of data.'
    },
    { 
        title:'Natural Language Processing',
        content:'Extrapolate data from human language, such as text, speech, numbers, names, and relationships. Accelerate reporting and analysis.'
    },
    { 
        title:'Computer Vision',
        content:'Create specialized software that can comprehend digital photos and videos. Objects are processed and analyzed, then categorized into categories and tracked.'
    },
    { 
        title:'AI Driven Chatbots Integrate Your Mobile Apps',
        content:'Integrate your mobile app(s) with services you are already using and broaden their use cases for your staff and users.'
    },
    { 
        title:'Data Science & Research',
        content:'Investigating unstructured datasets, data processing, creating predictive models, and transforming these into useful information are all part of our offerings.'
    },
    { 
        title:'AI Product Development',
        content:'You may utilize the tools and frameworks of your choosing to create AI solutions quickly thanks to our expertise in both for-profit and open-source AI.'
    },
]
export default function BoxSections() {
    return (
        <>
            <section className='boxSections'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head_section'>
                                <h2 className='h2_title'>Our AI Development Services</h2>
                            </div>
                        </Col>
                        {NFTCate.map((e, key) => {
                            return <Col sm={6} md={6} lg={4} xl={4} key={key}>
                                <div className='box_shell'>
                                    <BsBox />
                                    <h5 className='h5_title'>{e.title}</h5>
                                    <p>{e.content}</p>
                                </div>
                            </Col>
                        })
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}
