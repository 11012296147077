import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import BannerForm from '../../common/BannerForm';
export default function HeroSections() {


    return (
        <>
            <section className='nft_heroSections'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={8} xl={8}>
                            <div className='nftHero-about-wrap'>
                                <h2 className='h2_title'>Top Node.js Development Company</h2>
                                <p>Infograins is a reputable Node js development firm known for providing scalable and feature-rich online and mobile app solutions with a user-friendly front end and lightning-fast back end. We offer Node js web application development services to help you create enterprise solutions based on the asynchronous event-driven JavaScript runtime. Utilize our Node.js application development knowledge to create technologically advanced enterprise solutions that will strengthen the backend of your system.</p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4}>
                            <BannerForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
