import React from 'react';
// import '../Style/Componentscss/VirtualWorld.css';

function Virtual() {
    return (
        <>
            <div className='Virtual_Sec'> 
                <div className='Virtual_Content'>
                    <div className='Virtual_Heading'>
                        <h3>Bringing Blockchain Development To The City Of Pearls</h3>
                    </div>
                    <div className='Virtual_Paraone'>
                        <p>Infograins' Hyderabad office will offer reliable and innovative blockchain solutions. Our office in the city will be the centre of excellence for blockchain development to boost innovation for our esteemed clientele. With our extensive experience and expertise in the field, we are confident in our ability to optimize business operations, enhance security, and increase efficiency for our esteemed clientele.</p>
                    </div>
                    {/* <div className='Virtual_Paratwo'>
                        <p>When the spatial web (Web 3.0) is fully developed, the current distinction
                            between digital content and actual objects will be gone. Since digital
                            information will be integrated with and inseparable from the physical
                            environment, we refer to it as "spatial." In ways, we are only now starting to
                            envision, the Spatial Web era will open up new options to enhance
                            productivity, communication, and enjoyment. It will open up new possibilities
                            for the corporate benefit of visionary executives.</p>
                    </div> */}
                </div>
                <div className='Virtual_Image'>
                    <div className='Virtual_Img'></div>
                </div>
            </div>
        </>
    )
}

export default Virtual 