import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoxSections from './BoxSections'
import ContentDiv from './ContentDiv'
import HeroSections from './HeroSections'
import KeyFeature from './KeyFeature'
import { Helmet } from 'react-helmet'

export default function Main_nft(props) {
    const { nft_slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [nft_slug])
    // =========================== scroll To Top default =========================
    return (
        <>
           <Helmet>
                <title>UI/UX Design Services – Infograins</title>
                <meta name="description" content="With over a decade of expertise in software, we assist our clients in enhancing their brand's value through our UI/UX Design Services." />
            </Helmet>
            <HeroSections />
            <BoxSections />
            <ContentDiv />
            <KeyFeature />
        </>
    )
}
