import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Kotlin App Development',
        content: 'Using Kotlin, we create feature-rich, fast, cross-platform Android apps.'
    },
    {
        title: 'Kotlin for the Web',
        content: 'Kotlin facilitates integration with pre-existing third-party libraries and frameworks while speeding up and simplifying web development.'
    },
    {
        title: 'Server-side Programming',
        content: 'Create scalable, user-friendly server-side programs that work with the current Java Stack.'
    },
    {
        title: 'Kotlin App Migration',
        content: 'Existing programs\' code readability, performance, safety, and interoperability can all be enhanced using Kotlin.'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title'>Best Kotlin App Development Services</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements