import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import NeWImg from '../../../assets/media/TransformingBusinesses.png'

export default function TransformingBusinesses() {
    return (
        <>
            <section className='case_studies_details_banner'>
                <Container>
                    <Row>
                    <h2 className='ai_h2_title'style={{textAlign:"center"}}>Why Choose Infograins as Your AI Development Company in India? </h2>
                        <Col lg={6}>
                            <div className='content_wrapper'>
                                <div className='banner_content'>
                                    <p>At Infograins, we pride ourselves on being one of the Smart AI development company in India, delivering innovative solutions that redefine business processes and drive success. Our expertise spans a wide range of artificial intelligence services, including machine learning, natural language processing (NLP), computer vision, and predictive analytics. We help businesses unlock the true potential of AI by crafting tailored solutions designed to enhance efficiency, improve decision-making, and optimize operations.</p>
                                  <br/>  <p>With a proven track record of delivering AI-driven applications across industries such as healthcare, finance, retail, and logistics, Infograins empowers businesses to stay ahead in an increasingly competitive digital landscape. We have emerged as one of the Fastest growing AI Development Company in India. Our team of AI experts leverages the latest technologies and frameworks to build intelligent systems that analyze data, automate processes, and provide actionable insights. By partnering with Infograins, you gain access to state-of-the-art AI solutions that not only transform your operations but also pave the way for long-term growth and innovation.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='banner_img_wrapper'>
                                <Image src={NeWImg} className="img_side" alt='banner' fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
