import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

const Technologies = () => {
    return (
        <>
            <section className='w3-technologies-wrap'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='w3-technologies-content'>
                                <h3 className='h3_title'>Infograins AI</h3>
                                <h2 className='h2_title'>Creating Useful AI Interactions</h2>
                                <div className='description'>Infograins AI creates tools based on AI and cognitive architecture that give our robots the ability to mimic human personalities, engage in meaningful social relationships, and change as a result of those interactions. In order to provide the most appealing robotics and AI platform for research, media, and service applications, our team of renowned AI scientists engages in cutting-edge research.
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='web3-technologies-img'>
                                <Image src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_three/web_paragraph-2_waymkf'} alt="technologies-img" fluid />
                            </figure>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Technologies