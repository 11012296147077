import React from 'react';
import { Container, Row, Col, Form, Button, Image, Spinner } from "react-bootstrap"
import Background from '../../../assets/images/background/web3/banner3.jpg';
import BannerForm from '../../common/BannerForm';
const HeroSection = () => {

  return (
    <>
      <section className='web3-hero-wrap'>
        <Image className='background_img' src={Background} fluid />
        <Container>
          <Row>
            <Col sm={6} md={6} lg={8} xl={8}>
              <div className='w3-about-wrap'>
                <h2 className='h2_title'>Unity App</h2>
                <h3 className='h3_title'>Unity 3D Game Development</h3>
                <p>Infograins is a renowned Unity3D game production studio. We have worked on numerous web-based and mobile gaming projects and are professionals in the production of unity 3d apps. Direct3D, Open GL, and Open GL ES are all technologies that our team of skilled Unity developers is an expert in. We at Infograins, a firm that develops unity3D games, offer specialized and comprehensive services to a wide range of business sectors.</p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <BannerForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HeroSection