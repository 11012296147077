import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { AiFillFire } from 'react-icons/ai'
const KeyData = [
    {
        title: 'Listen to Your Needs',
        content:'Infograins takes the time to understand your WordPress website development demands, requirements, and ambitions. This provides us with a comprehensive grasp of your business objectives and expectations of us.'
    },
    {
        title: 'Research',
        content:'We begin the research process after we have a thorough understanding of your project\'s requirements. Our staff will go over your project requirements and look for the best ways to carry them out.'
    },
    {
        title: 'Plan',
        content:'Our expertise will collaborate to develop a cost-effective and efficient project execution strategy. This will assist us in determining the next steps to take and when the assignment should be completed and delivered.'
    },
    {
        title: 'Design and Develop',
        content:'Our expert website designers and competent developers will collaborate on your project to create an SEO-friendly, responsive, and bug-free website.'
    },
]
export default function KeyFeature() {
    return (
        <>
            <section className='keyFeature_section'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='head'>
                                {/* <h3 className='h3_title'>{head.subheading}</h3> */}
                                <h2 className='h2_title'>Our Process</h2>
                                <p>We take a client-focused approach to work, combined with agility and supported by extensive research. Here's how we operate:</p>
                            </div>
                        </Col>
                        {KeyData.map((e, key) => {
                                    return <Col sm={6} md={6} lg={3} xl={3} key={key}>
                                        <div className='key_box'>
                                            <AiFillFire />
                                            <h4 className='h4_title'>{e.title}</h4>
                                            <p>{e.content}</p>
                                        </div>
                                    </Col>
                                })
                        }

                    </Row>
                </Container>
            </section>
        </>
    )
}
