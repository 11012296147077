import React from 'react'
import PressHero from './PressHero'
import PreviousEvents from './PreviousEvents'

function PressMain() {
  return (
    <div>
        <PressHero/>
        <PreviousEvents/>
    </div>
  )
}

export default PressMain