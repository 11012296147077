import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import designImg from "../../../assets/images/design.png"
export default function ContentDiv() {
  
    return (
        <>
            <section className='content_div_section'>
                <Container>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_div_cont'>
                                <div className='content_div'>
                                    <h2 className='h2_title'>Contact Today to Unlock Your IoT System's Full Potential with QA</h2>
                                    {/* <p>{NFTCate.title}</p> */}
                                    <p>Our complete Quality Assurance (QA) services are here to ensure that your IoT solutions run smoothly, scale easily, and remain secure in an interconnected world. Don't leave your IoT success to chance—contact us today to see how our QA experience may help your IoT system reach new heights.</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <div className='content_img'>
                                <Image src={designImg} fluid />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
