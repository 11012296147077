import React from 'react'
// import { BiLogoLinkedin } from 'react-icons/bi'
import { AiFillLinkedin, AiFillFacebook, AiFillTwitterSquare, AiOutlineInstagram } from "react-icons/ai"
export default function Footer() {
    return (
        <>
            <footer>
                <div className='copyright'>
                    Copyright © Infograins Software Solution Pvt. Ltd. 2023 All Rights Reserved
                </div>
                <div className='social_links'>
                    <AiFillFacebook />
                    <AiFillTwitterSquare />
                    <AiOutlineInstagram />
                    <AiFillLinkedin />
                </div>
            </footer>
        </>
    )
}
